import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import {
    createAsyncThunkWrapper,
    dispatchOneOrMany,
    getStateQuery,
    type ThunkApiType,
} from '@shared/store/store.utils';
import { type AppState } from '@store/store';
import { clearCurrentAction } from './clear.actions';
import { type ActionCrudOrArray, type ThunkCrudOrArray, type ThunkOptions } from './crud.types';
import { ACTION_TYPE_PREFIX, handleToast } from './crud.utils';

type DeleteServiceFunction<IdType = number, Return = void> = (
    id: IdType,
    thunkApi: ThunkApiType,
    state: AppState,
) => Promise<Return>;
type DeleteSingleServiceFunction = (thunkApi: ThunkApiType, state: AppState) => Promise<void>;

export type DeleteThunkParams = {
    id: number;
    needGetAll: boolean;
};

type DeleteThunkWithListAndCurrentOptions<IdType = number, Query = unknown, Return = void> = ThunkOptions<
    (query: Query) => ThunkCrudOrArray,
    DeleteServiceFunction<IdType, Return>,
    Return
>;

export const deleteWithListThunk = <Query, Return = void>({
    stateKey,
    serviceFunction,
    thunksOnSuccess,
    successMessage,
    getName,
}: DeleteThunkWithListAndCurrentOptions<number, Query, Return>): ReturnType<
    typeof createAsyncThunk<void, DeleteThunkParams, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, DeleteThunkParams>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/delete-one`,
        serviceFunction: async ({ id, needGetAll }, thunkApi, state) => {
            const { dispatch } = thunkApi;
            const response = await serviceFunction(id, thunkApi, state);
            if (needGetAll) {
                if (thunksOnSuccess) {
                    const query: Query = getStateQuery(state, stateKey);
                    dispatchOneOrMany(thunksOnSuccess(query), dispatch);
                }
            }
            const suffixName = getName?.(response);
            handleToast(dispatch, 'success', successMessage, suffixName);
        },
    });
};

type DeleteThunkSingleOptions = ThunkOptions<() => ActionCrudOrArray | ThunkCrudOrArray, DeleteSingleServiceFunction>;

export const deleteSingleThunk = ({
    stateKey,
    serviceFunction,
    thunksOnSuccess,
    successMessage,
}: DeleteThunkSingleOptions): ReturnType<
    typeof createAsyncThunk<void, void | undefined, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, void | undefined>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/delete-one`,
        serviceFunction: async (_, thunkApi, state) => {
            const { dispatch } = thunkApi;
            await serviceFunction(thunkApi, state);
            if (thunksOnSuccess) {
                dispatchOneOrMany(thunksOnSuccess(), dispatch);
            } else {
                thunkApi.dispatch(clearCurrentAction());
            }
            handleToast(dispatch, 'success', successMessage);
        },
    });
};
