import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enSignIn from '@auth/sign-in/i18n/en.json';
import ukSignIn from '@auth/sign-in/i18n/uk.json';
import ruSignIn from '@auth/sign-in/i18n/ru.json';
import enSetPassword from '@auth/set-password/i18n/en.json';
import enSetPasswordForm from '@auth/set-password/set-password-form/i18n/en.json';
import ukSetPassword from '@auth/set-password/i18n/uk.json';
import ukSetPasswordForm from '@auth/set-password/set-password-form/i18n/uk.json';
import ruSetPassword from '@auth/set-password/i18n/ru.json';
import ruSetPasswordForm from '@auth/set-password/set-password-form/i18n/ru.json';
import enForgotPassword from '@auth/forgot-password/i18n/en.json';
import ukForgotPassword from '@auth/forgot-password/i18n/uk.json';
import ruForgotPassword from '@auth/forgot-password/i18n/ru.json';

export const authI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.SIGN_IN]: enSignIn,
        [TranslationsNameSpaces.SET_PASSWORD]: enSetPassword,
        [TranslationsNameSpaces.SET_PASSWORD_FORM]: enSetPasswordForm,
        [TranslationsNameSpaces.FORGOT_PASSWORD]: enForgotPassword,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.SIGN_IN]: ukSignIn,
        [TranslationsNameSpaces.SET_PASSWORD]: ukSetPassword,
        [TranslationsNameSpaces.SET_PASSWORD_FORM]: ukSetPasswordForm,
        [TranslationsNameSpaces.FORGOT_PASSWORD]: ukForgotPassword,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.SIGN_IN]: ruSignIn,
        [TranslationsNameSpaces.SET_PASSWORD]: ruSetPassword,
        [TranslationsNameSpaces.SET_PASSWORD_FORM]: ruSetPasswordForm,
        [TranslationsNameSpaces.FORGOT_PASSWORD]: ruForgotPassword,
    },
};
