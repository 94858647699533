import { clearCurrentAction, clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { SettingsMailBoxThunks } from './mail-box.thunk';
import { type MailBox } from './mail-box.types.ts';
import { setStateValue } from '@shared/store/store.utils.ts';

export type SettingsMailState = StateSingle<MailBox> & {
    sendingTest: boolean;
};

export const settingsMailBoxThunks = new SettingsMailBoxThunks();

const { stateKey, defaultThunks, sendTestThunk } = settingsMailBoxThunks;

const initialState: SettingsMailState = { ...getInitialStateSingle(), sendingTest: false };

export const emailSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentAction, clearCurrentState], (builder) =>
        builder
            .addCase(sendTestThunk.pending, setStateValue('sendingTest', true))
            .addCase(sendTestThunk.fulfilled, setStateValue('sendingTest', false))
            .addCase(sendTestThunk.rejected, setStateValue('sendingTest', false)),
    ),
});

export default emailSettingsSlice.reducer;
