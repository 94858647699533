import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

export const capitalizeFirstLetter = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1);

export type OmitUndefined<T extends object> = {
    [K in keyof T as undefined extends T[K] ? never : K]: T[K];
};

export const optionalFields = <T extends object>(obj: T): OmitUndefined<T> => {
    return omitBy(obj, isUndefined) as OmitUndefined<T>;
};

export const toUpperCaseWithUnderscores = (key: string): string => {
    return key
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        .replace(/ /g, '_')
        .replace(/([a-z])/g, (char) => char.toUpperCase());
};
