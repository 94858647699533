import { cn } from '@shared/class-names/class-names.utils.ts';
import CloseSvg from '@shared/close/close-svg.tsx';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import TypeSvg from '@shared/type-svg/type-svg.tsx';
import { useAppDispatch, useAppSelector } from '@store/store.hooks.ts';
import { type HTMLProps, type MouseEvent, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { removeToast } from './store/toast.slice.ts';
import { type Toast } from './store/toast.thunk.ts';

const Toasts = ({ className = '' }: HTMLProps<HTMLDivElement>): ReactElement | null => {
    const { t } = useTranslation(TranslationsNameSpaces.TOAST);
    const toasts = useAppSelector((state) => state.toasts);
    const dispatch = useAppDispatch();

    const toastClassMap: { [key in Toast['type']]: string } = {
        success: `bg-green-light-6 dark:bg-green-dark border-green`,
        error: `bg-red-light-6 dark:bg-red-dark border-red-dark`,
        warning: `bg-yellow-light-4 dark:bg-yellow-dark border-yellow`,
        info: `bg-blue-light-5 dark:bg-blue-dark border-blue-light`,
    };

    const close =
        (toastId: string) =>
        (_: MouseEvent): void =>
            dispatch(removeToast(toastId));

    const getMessage = (toast: Toast): string => {
        if (!toast?.suffixName) {
            return t(toast.message, { ...toast.props });
        }
        return `${t(toast.message)} - ${toast.suffixName}`;
    };

    if (!toasts.length) {
        return null;
    }

    const divClassnames = cn('toast toast-center z-20 sm:w-full w-519px', className);

    return (
        <div className={divClassnames}>
            {toasts?.map((toast: Toast) => {
                const toastClassName = toastClassMap[toast.type];
                return (
                    <div
                        className={cn(
                            'flex items-center min-h-56px text-wrap text-center border-l-4 rounded-6px pl-3.5',
                            toastClassName,
                        )}
                        key={toast.id}
                    >
                        <TypeSvg type={toast.type} />
                        <div className="flex ml-5 justify-between items-center w-full">
                            <span className="text-dark text-small-medium whitespace-pre-line">{getMessage(toast)}</span>
                            <div className="mr-5 cursor-pointer" onClick={close(toast.id)}>
                                <CloseSvg />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Toasts;
