import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types.ts';
import { deleteWithListThunk } from '@pages/dashboard/shared/crud/delete.thunks.ts';
import { getListThunk } from '@pages/dashboard/shared/crud/get.thunks.ts';
import * as backupsService from './backups.service';
import { type BackupList, type BackupListQuery } from './backups.types.ts';

export const getBackupsThunk = getListThunk<Pagination<BackupList>, BackupListQuery>({
    serviceFunction: backupsService.getBackups,
    stateKey: 'backups',
});

export const deleteBackupThunk = deleteWithListThunk({
    stateKey: 'backups',
    successMessage: 'BACKUPS.DELETED',
    serviceFunction: backupsService.deleteBackup,
    thunksOnSuccess: getBackupsThunk,
});
