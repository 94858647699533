import { AttachmentListWithBulkApiService } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-api.service';
import { type CreateLink, type Link, type LinksQuery } from './links.types';

export class LinksService extends AttachmentListWithBulkApiService<Link, LinksQuery, CreateLink, Partial<Link>> {
    public constructor() {
        super('links');
    }
}

const linksService = new LinksService();
export default linksService;
