import { deleteWithListThunk } from '@pages/dashboard/shared/crud/delete.thunks';
import { getListThunk } from '@pages/dashboard/shared/crud/get.thunks';
import { updateInPlaceThunk } from '@pages/dashboard/shared/crud/update.thunks';
import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import { type ListWithBulkApiService } from './list-with-bulk-api.service';
import { createBulkThunk } from '../create.thunks';
import { type StateKey } from '@store/state.types';

export abstract class ListWithBulkThunks<
    Service extends ListWithBulkApiService<List, Query, Create, Update>,
    List,
    Query,
    Create,
    Update,
> {
    public readonly bulkThunk;
    public readonly getAllThunk;
    public readonly updateInPlaceThunk;
    public readonly deleteThunk;

    public constructor(
        public readonly service: Service,

        public readonly stateKey: StateKey,
    ) {
        this.getAllThunk = getListThunk<List[], Query>({
            stateKey,
            serviceFunction: service.getAll.bind(service),
        });

        this.updateInPlaceThunk = updateInPlaceThunk<Update, Query>({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.UPDATED`,
            serviceFunction: service.update.bind(service),
            thunksOnSuccess: this.getAllThunk,
        });

        this.deleteThunk = deleteWithListThunk({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.DELETED`,
            serviceFunction: service.delete.bind(service),
            thunksOnSuccess: this.getAllThunk,
        });

        this.bulkThunk = createBulkThunk<Create[], Query>({
            stateKey,
            serviceFunction: service.bulk.bind(service),
            thunksOnSuccess: (_, query) => this.getAllThunk(query),
            successMessage: `${stateKey.toUpperCase()}.BULK`,
        });
    }

    public get defaultThunks(): ReturnType<typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>>[] {
        return [this.bulkThunk, this.deleteThunk, this.getAllThunk, this.updateInPlaceThunk];
    }
}
