import { type ReactElement, type SVGProps } from 'react';
import './loader-svg.scss';

type LoaderSvgProps = SVGProps<SVGSVGElement>;
const LoaderSvg = ({ className = '' }: LoaderSvgProps): ReactElement | null => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            className={`animate-spin ${className}`}
        >
            <path
                d="M30.5 16C30.5 13.7118 29.9584 11.456 28.9196 9.41714C27.8808 7.3783 26.3741 5.61426 24.5229 4.26926"
                stroke="#3E57F0"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default LoaderSvg;
