import httpClient from '@shared/http/http.service.ts';
import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types.ts';
import { type BackupListQuery, type BackupList } from './backups.types.ts';

const PREFIX = 'backups';

export const getBackups = (params?: BackupListQuery): Promise<Pagination<BackupList>> => {
    return httpClient.get(PREFIX, { params });
};

export const deleteBackup = (id: number): Promise<void> => {
    return httpClient.delete(`${PREFIX}/${id}`);
};
