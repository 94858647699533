import httpClient from '@shared/http/http.service';
import { type CreateProject, type Project, type ProjectList, type ProjectsFilterQuery } from './projects.types';
import { ListAndCurrentApiService } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-api.service';

export class ProjectsService extends ListAndCurrentApiService<
    Project,
    ProjectList,
    ProjectsFilterQuery,
    CreateProject
> {
    public constructor() {
        super('projects');
    }

    public finishProject(id: number): Promise<void> {
        return httpClient.post(`${this.prefix}/${id}/finish`);
    }

    public reopenProject(id: number): Promise<void> {
        return httpClient.post(`${this.prefix}/${id}/reopen`);
    }
}

const projectsService = new ProjectsService();
export default projectsService;
