import { SingleThunks } from '@pages/dashboard/shared/crud/single/single-thunks.ts';
import { type Create } from '@shared/create/create.types.ts';
import settingsDatabaseBackupService, { type SettingsDatabaseBackupService } from './database-backup.service.ts';
import { type DatabaseBackup } from './database-backup.types.ts';

export class SettingsDatabaseBackupThunks extends SingleThunks<
    SettingsDatabaseBackupService,
    DatabaseBackup,
    Create<DatabaseBackup>
> {
    public constructor() {
        super(settingsDatabaseBackupService, 'settings.databaseBackup');
    }
}
