import { type ReactElement } from 'react';

const InfoSvg = (): ReactElement => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#5475E5" />
            <path
                d="M20.3625 24.75H18.6469V15.075C18.6469 14.2875 18 13.6687 17.2407 13.6687H15.6657C15.3282 13.6687 15.0188 13.95 15.0188 14.3156C15.0188 14.6812 15.3 14.9625 15.6657 14.9625H17.2407C17.325 14.9625 17.3813 15.0187 17.3813 15.1031V24.75H15.6657C15.3282 24.75 15.0188 25.0312 15.0188 25.3969C15.0188 25.7625 15.3 26.0437 15.6657 26.0437H20.3907C20.7282 26.0437 21.0375 25.7625 21.0375 25.3969C21.0375 25.0312 20.7 24.75 20.3625 24.75Z"
                fill="white"
            />
            <path
                d="M17.8594 12.2906C18.3254 12.2906 18.7031 11.9129 18.7031 11.4469C18.7031 10.9809 18.3254 10.6031 17.8594 10.6031C17.3934 10.6031 17.0156 10.9809 17.0156 11.4469C17.0156 11.9129 17.3934 12.2906 17.8594 12.2906Z"
                fill="white"
            />
        </svg>
    );
};

export default InfoSvg;
