import { ListWithBulkThunks } from '@pages/dashboard/shared/crud/list-with-bulk/list-with-bulk-thunks.ts';
import holidaysService, { type HolidaysService } from './holidays.service.ts';
import { type CreateHoliday, type Holiday, type HolidaysFilterQuery, type UpdateHoliday } from './holidays.types.ts';

export class HolidaysThunks extends ListWithBulkThunks<
    HolidaysService,
    Holiday,
    HolidaysFilterQuery,
    CreateHoliday,
    UpdateHoliday
> {
    public constructor() {
        super(holidaysService, 'holidays');
    }
}
