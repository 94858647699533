import { EntityType } from '@libraries/entity-type/entity-type.enum.ts';
import { AttachmentListWithBulkThunks } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-thunks.ts';
import linksService, { type LinksService } from './links.service.ts';
import { type CreateLink, type Link, type LinksQuery } from './links.types.ts';

export class LinksThunks extends AttachmentListWithBulkThunks<
    LinksService,
    Link,
    LinksQuery,
    CreateLink,
    Partial<Link>
> {
    public constructor() {
        super(linksService, EntityType.LINKS);
    }
}
