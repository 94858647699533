import { showToast } from '@shared/toast/store/toast.thunk';
import type { AppDispatch } from '@store/store';
import imageCompression from 'browser-image-compression';
import { type ImageOrFile } from './image.types';

export const getImageFileFromUrl = async (url: string): Promise<File | null> => {
    try {
        const res = await fetch(url);
        const blob = await res.blob();
        const type = url.split('.').at(-1) || 'png';
        return new File([blob], `image.${type}`, { type: `image/${type}` });
    } catch {
        return null;
    }
};

export const isImageOrFileInstanceFile = (image: ImageOrFile): image is File => {
    return image instanceof File;
};

export const handleImage = async (imageFile: File | null, dispatch: AppDispatch): Promise<File | null> => {
    if (!imageFile) {
        return null;
    }
    const options = {
        maxWidthOrHeight: 1200,
        useWebWorker: true,
        fileType: 'image/webp',
        initialQuality: 0.95,
    };
    try {
        const compressedBlob = await imageCompression(imageFile, options);
        const compressedFile = new File([compressedBlob], `${imageFile.name}.webp`, {
            type: compressedBlob.type,
            lastModified: Date.now(),
        });
        return compressedFile;
    } catch (error: any) {
        const defaultErrorMessage = 'The file given is not an image';
        const message = error?.message === defaultErrorMessage ? 'IMAGE_ERRORS.ONLY_IMAGE' : 'BASE_ERROR';
        dispatch(showToast({ type: 'error', message, props: { fileName: imageFile.name } }));
        return null;
    }
};

export const handleImages = async (images: File[], dispatch: AppDispatch): Promise<File[]> => {
    return (await Promise.all(images.map((image) => handleImage(image, dispatch)))).filter(Boolean) as File[];
};
