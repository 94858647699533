import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enCandidates from '@recruiting/candidates/i18n/en.json';
import enCandidate from '@recruiting/candidates/candidate/i18n/en.json';
import ukCandidates from '@recruiting/candidates/i18n/uk.json';
import ukCandidate from '@recruiting/candidates/candidate/i18n/uk.json';
import ruCandidates from '@recruiting/candidates/i18n/ru.json';
import ruCandidate from '@recruiting/candidates/candidate/i18n/ru.json';
import enOpenPositions from '@recruiting/open-positions/i18n/en.json';
import enOpenPosition from '@recruiting/open-positions/open-position/i18n/en.json';
import ukOpenPositions from '@recruiting/open-positions/i18n/uk.json';
import ukOpenPosition from '@recruiting/open-positions/open-position/i18n/uk.json';
import ruOpenPositions from '@recruiting/open-positions/i18n/ru.json';
import ruOpenPosition from '@recruiting/open-positions/open-position/i18n/ru.json';

export const recruitingI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.CANDIDATES]: enCandidates,
        [TranslationsNameSpaces.CANDIDATE]: enCandidate,
        [TranslationsNameSpaces.OPEN_POSITIONS]: enOpenPositions,
        [TranslationsNameSpaces.OPEN_POSITION]: enOpenPosition,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.CANDIDATES]: ukCandidates,
        [TranslationsNameSpaces.CANDIDATE]: ukCandidate,
        [TranslationsNameSpaces.OPEN_POSITIONS]: ukOpenPositions,
        [TranslationsNameSpaces.OPEN_POSITION]: ukOpenPosition,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.CANDIDATES]: ruCandidates,
        [TranslationsNameSpaces.CANDIDATE]: ruCandidate,
        [TranslationsNameSpaces.OPEN_POSITIONS]: ruOpenPositions,
        [TranslationsNameSpaces.OPEN_POSITION]: ruOpenPosition,
    },
};
