import { createAsyncThunkWrapper } from '@shared/store/store.utils';
import { type Lookup, type LookupName } from './lookup.types.ts';
import * as lookupsService from './lookups.service';

export const lookupsGet = 'lookups/get';

export type LookupThunkParams = { lookupName: LookupName; needSilentFetch?: boolean };

export const getLookupsThunk = createAsyncThunkWrapper<Lookup[], LookupThunkParams>({
    actionType: lookupsGet,
    serviceFunction: ({ lookupName }) => lookupsService.getLookups(lookupName),
});
