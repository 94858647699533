import { type Activity } from '@activity-log/store/activity-log.types.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type ItemsCommonState } from '@store/state.types.ts';
import { getRecentActivitiesThunk } from './recent-activities.thunk.ts';

export type RecentActivityState = ItemsCommonState<Activity>;

const initialState: RecentActivityState = {
    isInitialized: false,
    items: [],
    pending: false,
};

const stateKey = 'recentActivities';

export const recentActivitiesSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        clearRecentActivities() {
            return initialState;
        },
        rejectRecentActivities() {
            return { ...initialState, isInitialized: true };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecentActivitiesThunk.pending, (state) => {
                state.pending = true;
                state.isInitialized = false;
            })
            .addCase(getRecentActivitiesThunk.fulfilled, (state, { payload }) => {
                state.pending = false;
                state.items = payload;
                state.isInitialized = true;
            });
    },
});

export const { clearRecentActivities, rejectRecentActivities } = recentActivitiesSlice.actions;

export default recentActivitiesSlice.reducer;
