import { type ReactElement, Suspense, lazy } from 'react';
import { Navigate, Outlet, type RouteObject } from 'react-router-dom';
import { type RouteConfiguration } from './route.types.ts';
const ErrorBoundary = lazy(() => import('../pages/error-boundary/error-boundary.tsx'));

type ComponentSection = {
    lazy?(): Promise<{ Component: () => ReactElement | null }>;
    element?: ReactElement;
};

export function getRoutesByConfiguration(configuration: RouteConfiguration[]): RouteObject[] {
    return configuration.map(
        ({ componentPath, redirectTo, path, soon = false, handle, children, element }: RouteConfiguration) => {
            if (soon) {
                return {
                    Component: () => (
                        <Suspense>
                            <ErrorBoundary />
                        </Suspense>
                    ),
                };
            }

            let view: ComponentSection = {} as ComponentSection;
            let component: { Component: () => ReactElement | null };

            if (componentPath) {
                view = {
                    async lazy(): Promise<{ Component: () => ReactElement | null }> {
                        try {
                            const paths = componentPath?.split('/') ?? [];
                            if (paths.length === 1) {
                                component = await import(`../pages/${paths[0]}.tsx`);
                            }
                            if (paths.length === 2) {
                                component = await import(`../pages/${paths[0]}/${paths[1]}.tsx`);
                            }
                            if (paths.length === 3) {
                                component = await import(`../pages/${paths[0]}/${paths[1]}/${paths[2]}.tsx`);
                            }
                            if (paths.length === 4) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}.tsx`
                                );
                            }
                            if (paths.length === 5) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}.tsx`
                                );
                            }
                            if (paths.length === 6) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}.tsx`
                                );
                            }
                            if (paths.length === 7) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}/${paths[6]}.tsx`
                                );
                            }
                            if (paths.length === 8) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}/${paths[6]}/${paths[7]}.tsx`
                                );
                            }
                            if (paths.length === 9) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}/${paths[6]}/${paths[7]}/${paths[8]}.tsx`
                                );
                            }
                            if (paths.length === 10) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}/${paths[6]}/${paths[7]}/${paths[8]}/${path[9]}.tsx`
                                );
                            }
                            if (paths.length === 11) {
                                component = await import(
                                    `../pages/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}/${paths[4]}/${paths[5]}/${paths[6]}/${paths[7]}/${paths[8]}/${path[9]}/${path[10]}.tsx`
                                );
                            }
                            const { Component } = component;
                            return { Component };
                        } catch {
                            return {
                                Component: () => (
                                    <Suspense>
                                        <ErrorBoundary />
                                    </Suspense>
                                ),
                            };
                        }
                    },
                };
            } else if (redirectTo) {
                view = { element: <Navigate replace to={redirectTo} /> };
            } else if (element) {
                view = { element };
            } else {
                view = { element: <Outlet /> };
            }
            const route = {
                path: path,
                handle: handle,
                ...view,
                ErrorBoundary(): ReactElement {
                    return (
                        <Suspense>
                            <ErrorBoundary />
                        </Suspense>
                    );
                },
                children: children ? getRoutesByConfiguration(children) : [],
            };
            if (Array.isArray(children)) {
                route.children = getRoutesByConfiguration(children);
            }
            return route;
        },
    );
}
