import { type Config } from 'tailwindcss';
import daisyui from 'daisyui';
import plugin from 'tailwindcss/plugin';

const tailwindConfig: Config = {
    content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
    darkMode: 'class',
    safelist: [
        {
            pattern: /^min-size-/,
        },
        {
            pattern: /^size-with-min/,
        },
    ],
    theme: {
        extend: {
            screens: {
                smmd: { min: '770px', max: '935px' },
                s: { min: '1px', max: '480px' },
                sm: { min: '1px', max: '770px' },
                md: { min: '770px', max: '1201px' },
                ms: { min: '770px', max: '1350px' },
                lg: { min: '1201px', max: '1535px' },
                '2xl': { min: '1536px' },
                mh: { raw: '(max-height: 800px)' },
            },
            backgroundColor: {
                main: {
                    DEFAULT: 'var(--color-light)',
                    dark: 'var(--color-dark-background)',
                },
            },
            colors: {
                blue: {
                    DEFAULT: 'var(--color-blue)',
                    dark: 'var(--color-blue-dark)',
                    light: 'var(--color-blue-light)',
                    'light-2': 'var(--color-blue-light-2)',
                    'light-3': 'var(--color-blue-light-3)',
                    'light-4': 'var(--color-blue-light-4)',
                    'light-5': 'var(--color-blue-light-5)',
                },
                gray: {
                    DEFAULT: 'var(--color-gray)',
                    background: 'var(--color-gray-background)',
                    2: 'var(--color-gray-2)',
                    3: 'var(--color-gray-3)',
                    4: 'var(--color-gray-4)',
                    5: 'var(--color-gray-5)',
                    6: 'var(--color-gray-6)',
                    7: 'var(--color-gray-7)',
                },
                dark: {
                    DEFAULT: 'var(--color-dark)',
                    background: 'var(--color-dark-background)',
                    2: 'var(--color-dark-2)',
                    3: 'var(--color-dark-3)',
                    4: 'var(--color-dark-4)',
                    5: 'var(--color-dark-5)',
                    6: 'var(--color-dark-6)',
                    7: 'var(--color-dark-7)',
                    8: 'var(--color-dark-8)',
                },
                yellow: {
                    DEFAULT: 'var(--color-yellow)',
                    dark: 'var(--color-yellow-dark)',
                    'dark-2': 'var(--color-yellow-dark-2)',
                    light: 'var(--color-yellow-light)',
                    'light-2': 'var(--color-yellow-light-2)',
                    'light-3': 'var(--color-yellow-light-3)',
                    'light-4': 'var(--color-yellow-light-4)',
                },
                orange: {
                    DEFAULT: 'var(--color-orange)',
                    dark: 'var(--color-orange-dark)',
                    light: 'var(--color-orange-light)',
                    'light-2': 'var(--color-orange-light-2)',
                    'light-3': 'var(--color-orange-light-3)',
                    'light-4': 'var(--color-orange-light-4)',
                    'light-5': 'var(--color-orange-light-5)',
                },
                red: {
                    DEFAULT: 'var(--color-red)',
                    dark: 'var(--color-red-dark)',
                    light: 'var(--color-red-light)',
                    'light-2': 'var(--color-red-light-2)',
                    'light-3': 'var(--color-red-light-3)',
                    'light-4': 'var(--color-red-light-4)',
                    'light-5': 'var(--color-red-light-5)',
                    'light-6': 'var(--color-red-light-6)',
                },
                pink: {
                    DEFAULT: 'var(--color-pink)',
                    dark: 'var(--color-pink-dark)',
                    light: 'var(--color-pink-light)',
                    'light-2': 'var(--color-pink-light-2)',
                    'light-3': 'var(--color-pink-light-3)',
                    'light-4': 'var(--color-pink-light-4)',
                },
                purple: {
                    DEFAULT: 'var(--color-purple)',
                    dark: 'var(--color-purple-dark)',
                    'dark-2': 'var(--color-purple-dark-2)',
                    light: 'var(--color-purple-light)',
                    'light-2': 'var(--color-purple-light-2)',
                    'light-3': 'var(--color-purple-light-3)',
                    'light-4': 'var(--color-purple-light-4)',
                    'light-5': 'var(--color-purple-light-5)',
                },
                cyan: {
                    DEFAULT: 'var(--color-cyan)',
                    dark: 'var(--color-cyan-dark)',
                    light: 'var(--color-cyan-light)',
                    'light-2': 'var(--color-cyan-light-2)',
                    'light-3': 'var(--color-cyan-light-3)',
                },
                teal: {
                    DEFAULT: 'var(--color-teal)',
                    dark: 'var(--color-teal-dark)',
                    light: 'var(--color-teal-light)',
                    'light-2': 'var(--color-teal-light-2)',
                    'light-3': 'var(--color-teal-light-3)',
                },
                green: {
                    DEFAULT: 'var(--color-green)',
                    dark: 'var(--color-green-dark)',
                    light: 'var(--color-green-light)',
                    'light-2': 'var(--color-green-light-2)',
                    'light-3': 'var(--color-green-light-3)',
                    'light-4': 'var(--color-green-light-4)',
                    'light-5': 'var(--color-green-light-5)',
                    'light-6': 'var(--color-green-light-6)',
                },
                white: 'var(--color-white)',
                primary: {
                    white: 'var(--color-primary-white)',
                    input: 'var(--color-primary-input)',
                    color: 'var(--color-primary-color)',
                    'text-color': 'var(--color-primary-text-color)',
                },
                secondary: {
                    color: 'var(--color-secondary-color)',
                    'text-color': 'var(--color-secondary-text-color)',
                },
                stroke: 'var(--color-stroke)',
            },
            fontFamily: {
                sans: ['Inter', 'system-ui', 'sans-serif'],
            },
            fontSize: {
                h1: ['60px', '72px'],
                h2: ['48px', '58px'],
                h3: ['40px', '48px'],
                h4: ['30px', '38px'],
                h5: ['25px', '40px'],
                h6: ['24px', '30px'],
                'h6-semi-bold': [
                    '24px',
                    {
                        lineHeight: '30px',
                        fontWeight: '600',
                    },
                ],
                'large-24-bold': [
                    '24px',
                    {
                        lineHeight: '37px',
                        fontWeight: '700',
                    },
                ],
                'large-regular': [
                    '18px',
                    {
                        lineHeight: '26px',
                        fontWeight: '400',
                    },
                ],
                'large-medium': [
                    '18px',
                    {
                        lineHeight: '26px',
                        fontWeight: '500',
                    },
                ],
                'large-semi-bold': [
                    '18px',
                    {
                        lineHeight: '26px',
                        fontWeight: '600',
                    },
                ],
                'large-bold': [
                    '18px',
                    {
                        lineHeight: '26px',
                        fontWeight: '700',
                    },
                ],

                'medium-regular': [
                    '16px',
                    {
                        lineHeight: '24px',
                        fontWeight: '400',
                    },
                ],
                'medium-medium': [
                    '16px',
                    {
                        lineHeight: '24px',
                        fontWeight: '500',
                    },
                ],
                'medium-semi-bold': [
                    '16px',
                    {
                        lineHeight: '24px',
                        fontWeight: '600',
                    },
                ],
                'medium-bold': [
                    '16px',
                    {
                        lineHeight: '24px',
                        fontWeight: '700',
                    },
                ],

                'small-regular': [
                    '14px',
                    {
                        lineHeight: '22px',
                        fontWeight: '400',
                    },
                ],
                'small-medium': [
                    '14px',
                    {
                        lineHeight: '22px',
                        fontWeight: '500',
                    },
                ],
                'small-semi-bold': [
                    '14px',
                    {
                        lineHeight: '22px',
                        fontWeight: '600',
                    },
                ],
                'extra-small-regular': [
                    '12px',
                    {
                        lineHeight: '22px',
                        fontWeight: '400',
                    },
                ],
                'extra-small-medium': [
                    '12px',
                    {
                        lineHeight: '22px',
                        fontWeight: '500',
                    },
                ],
                'tiny-bold': [
                    '10px',
                    {
                        lineHeight: '18px',
                        fontWeight: '600',
                    },
                ],
            },
            borderRadius: {
                '3px': '3px',
                '4px': '4px',
                '6px': '6px',
                '8px': '8px',
                '12px': '12px',
                '14px': '14px',
                '15px': '15px',
                '25px': '25px',
                '30px': '30px',
            },
            borderWidth: {
                '2px': '2px',
                '3px': '3px',
            },
            minHeight: {
                '56px': '56px',
                '75px': '75px',
                '84px': '84px',
                '105px': '105px',
                '810px': '810px',
            },
            height: {
                '25px': '25px',
                '29px': '29px',
                '36px': '36px',
                '38px': '38px',
                '42px': '42px',
                '50px': '50px',
                '58px': '58px',
                '66px': '66px',
                '75px': '75px',
                '84px': '84px',
                '105px': '105px',
                '154px': '154px',
                '175px': '175px',
                '216px': '216px',
                '285px': '285px',
                '561px': '561px',
            },
            minWidth: {
                '115px': '115px',
                '200px': '200px',
                '305px': '305px',
                '324px': '324px',
                '335px': '335px',
                '355px': '355px',
                '400px': '400px',
                '636px': '636px',
            },
            maxWidth: {
                '115px': '115px',
                '305px': '305px',
                '324px': '324px',
                '335px': '335px',
                '380px': '380px',
                '400px': '400px',
                '636px': '636px',
            },
            width: {
                '13px': '13px',
                '25px': '25px',
                '34px': '34px',
                '38px': '38px',
                '88px': '88px',
                '92px': '92px',
                '100px': '100px',
                '115px': '115px',
                '216px': '216px',
                '264px': '264px',
                '305px': '305px',
                '324px': '324px',
                '335px': '335px',
                '355px': '355px',
                '393px': '393px',
                '400px': '400px',
                '519px': '519px',
                '525px': '525px',
                '636px': '636px',
            },
            size: {
                '16px': '16px',
                '18px': '18px',
                '20px': '20px',
                '24px': '24px',
                '32px': '32px',
                '34px': '34px',
                '36px': '36px',
                '38px': '38px',
                '42px': '42px',
                '52px': '52px',
                '80px': '80px',
            },
            spacing: {
                '3px': '3px',
                '5px': '5px',
                '6px': '6px',
                '15px': '15px',
                '20px': '20px',
                '22px': '22px',
                '25px': '25px',
                '30px': '30px',
                '35px': '35px',
                '40px': '40px',
                '85px': '85px',
                '90px': '90px',
            },
        },
    },
    daisyui: {
        base: false,
        themes: true,
    },
    plugins: [
        daisyui,
        plugin(function ({ matchUtilities, theme }) {
            matchUtilities(
                {
                    'min-size': (value) => ({
                        minWidth: value,
                        minHeight: value,
                    }),
                    'size-with-min': (value) => ({
                        minWidth: value,
                        minHeight: value,
                        width: value,
                        height: value,
                    }),
                },
                {
                    values: { ...theme('size') },
                },
            );
        }),
    ],
};

export default tailwindConfig;
