import httpClient from '@shared/http/http.service';
import { serializeDatesInObject } from '@shared/serializer/date-body.serializer';

export abstract class ListWithBulkApiService<R, Query, Create, Update> {
    public constructor(public readonly prefix: string) {}

    public delete(id: number): Promise<void> {
        return httpClient.delete(`${this.prefix}/${id}`);
    }

    public getAll(query: Query): Promise<R[]> {
        return httpClient.get(this.prefix, {
            params: query,
        });
    }

    public update(id: number, data: Update): Promise<void> {
        return httpClient.patch(`${this.prefix}/${id}`, data);
    }

    public bulk(bulk: Create[]): Promise<void> {
        return httpClient.post(`${this.prefix}/bulk`, { create: serializeDatesInObject(bulk) });
    }
}
