export enum CommonRoutes {
    COMPANY_SETTINGS = '/company/settings',
    COMPANY_SETTINGS_GENERAL = 'general',
    COMPANY_SETTINGS_EMAIL = 'mail-box',
    COMPANY_SETTINGS_DATABASE_BACKUP = 'database-backup',
    COMPANY_SETTINGS_HOLIDAYS = 'holidays',
    COMPANY_SETTINGS_WORKING_WEEK = 'working-week',
    COMPANY_SETTINGS_VACATION_POLICY = 'vacation-policy',
    COMPANY_SETTINGS_SICKNESS_POLICY = 'sickness-policy',
    COMPANY_SETTINGS_STORAGE = 'storage',
}
