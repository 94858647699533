import { PlainingThunks } from '@pages/dashboard/shared/crud/planing/planing-thunks';
import { type Create } from '@shared/create/create.types';
import settingsSicknessPolicyService, { type SettingsSicknessPolicyService } from './sickness-policy.service';
import { type SicknessPolicy } from './sickness-policy.types';

export class SettingsSicknessPolicyThunks extends PlainingThunks<
    SettingsSicknessPolicyService,
    SicknessPolicy,
    Create<SicknessPolicy>
> {
    public constructor() {
        super(settingsSicknessPolicyService, 'settings.sicknessPolicy');
    }
}
