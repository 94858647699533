import { Language } from '../language.enum';
import { ErrorKeys } from './error-keys.enum';
import enLocales from './i18n/en.json';
import ukLocales from './i18n/uk.json';
import ruLocales from './i18n/ru.json';

type Locale = typeof enLocales | typeof ruLocales | typeof ukLocales;

const languageJsonMap: { [key in Language]: Locale } = {
    [Language.EN]: enLocales,
    [Language.RU]: ruLocales,
    [Language.UK]: ukLocales,
};

type ErrorLocales = {
    [key in Language]: { [key in ErrorKeys]: string };
};

export const errorLocales: ErrorLocales = Object.keys(languageJsonMap).reduce((locales, lang) => {
    const languageKey = lang as Language;
    const messages = languageJsonMap[languageKey];
    locales[languageKey] = messages;
    return locales;
}, {} as ErrorLocales);
