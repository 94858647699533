import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import {
    createAsyncThunkWrapper,
    dispatchOneOrMany,
    getStateQuery,
    handleAxiosError,
    type ThunkApiType,
} from '@shared/store/store.utils';
import { type AppState } from '@store/store';
import { type ErrorCb, type ThunkCrudOrArray, type ThunkOptions } from './crud.types';
import { ACTION_TYPE_PREFIX, handleToast } from './crud.utils';

export type CreateServiceFunction<Params, Return = void> = (
    params: Params,
    thunkApi: ThunkApiType,
    state: AppState,
) => Promise<Return>;

// Create current only if items exist
type CreateWithListThunkOptions<Data, Query = unknown, Return = void> = ThunkOptions<
    (query: Query) => ThunkCrudOrArray,
    CreateServiceFunction<Data, Return>,
    Return
>;

export const createWithListThunk = <Data, Query = unknown, Return = void>({
    stateKey,
    serviceFunction,
    thunksOnSuccess,
    successMessage,
    getName,
}: CreateWithListThunkOptions<Data, Query, Return>): ReturnType<
    typeof createAsyncThunk<void, Data, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, Data>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/create-one`,
        serviceFunction: async (data, thunkApi, state) => {
            const { dispatch } = thunkApi;
            const response = await serviceFunction(data, thunkApi, state);
            if (thunksOnSuccess) {
                const query: Query = getStateQuery(state, stateKey);
                dispatchOneOrMany(thunksOnSuccess(query), dispatch);
            }
            const suffixName = getName?.(response);
            handleToast(dispatch, 'success', successMessage, suffixName);
        },
    });
};

type BulkThunkOptions<Data, Query> = ThunkOptions<
    (data: Data, query: Query) => ThunkCrudOrArray,
    CreateServiceFunction<Data>
>;

export const createBulkThunk = <Data, Query>({
    stateKey,
    serviceFunction,
    successMessage,
    thunksOnSuccess,
}: BulkThunkOptions<Data, Query>): ReturnType<
    typeof createAsyncThunk<void, Data, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, Data>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/bulk`,
        serviceFunction: async (data, thunkApi, state) => {
            const { dispatch } = thunkApi;
            await serviceFunction(data, thunkApi, state);
            if (thunksOnSuccess) {
                const query: Query = getStateQuery(state, stateKey);
                dispatchOneOrMany(thunksOnSuccess(data, query), dispatch);
            }
            handleToast(dispatch, 'success', successMessage);
        },
    });
};

export type CreateSingleThunkParams<Data, Query> = {
    data: Data;
    query?: Query;
};

type CreateSingleThunkOptions<Data> = ThunkOptions<
    (state: AppState) => ThunkCrudOrArray,
    CreateServiceFunction<Data, void>
> & {
    errorCb?: ErrorCb;
};

export const createSingleThunk = <Data>({
    stateKey,
    serviceFunction,
    successMessage,
    errorCb,
    thunksOnSuccess,
}: CreateSingleThunkOptions<Data>): ReturnType<
    typeof createAsyncThunk<void, Data, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<void, Data>({
        actionType: `${stateKey}/${ACTION_TYPE_PREFIX}/create-one`,
        errorCallback: (error, dispatch, state) => {
            const { status } = error.response?.data || {};
            if (status === 499 && thunksOnSuccess) {
                dispatchOneOrMany(thunksOnSuccess(state), dispatch);
                return;
            }

            return errorCb?.(error, dispatch) || handleAxiosError(error, dispatch);
        },
        serviceFunction: async (data, thunkApi, state) => {
            const { dispatch } = thunkApi;
            await serviceFunction(data, thunkApi, state);
            if (thunksOnSuccess) {
                dispatchOneOrMany(thunksOnSuccess(state), dispatch);
            }
            handleToast(dispatch, 'success', successMessage);
        },
    });
};
