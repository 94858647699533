import { type Create } from '@shared/create/create.types';
import { type SicknessPolicy } from './sickness-policy.types';
import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service';

export class SettingsSicknessPolicyService extends SingleApiService<SicknessPolicy, Create<SicknessPolicy>> {
    public constructor() {
        super('absence-policies/sickness');
    }
}
const settingsSicknessPolicyService = new SettingsSicknessPolicyService();
export default settingsSicknessPolicyService;
