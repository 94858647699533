import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service';
import { type CreateHolidaysSchedule, type HolidaysSchedule } from './holidays-schedule.types';

export class SettingsHolidaysScheduleService extends SingleApiService<HolidaysSchedule, CreateHolidaysSchedule> {
    public constructor() {
        super('holidays-schedule');
    }
}
const settingsHolidaysScheduleService = new SettingsHolidaysScheduleService();
export default settingsHolidaysScheduleService;
