import { AttachmentListWithBulkThunks } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-thunks.ts';
import credentialsService, { type CredentialsService } from './credentials.service.ts';
import { type CreateCredential, type Credential, type CredentialsQuery } from './credentials.types.ts';

export class CredentialsThunks extends AttachmentListWithBulkThunks<
    CredentialsService,
    Credential,
    CredentialsQuery,
    CreateCredential
> {
    public constructor() {
        super(credentialsService, 'credentials');
    }
}
