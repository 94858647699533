import { type ReactElement } from 'react';

const ErrorSvg = (): ReactElement => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#E10E0E" />
            <g clipPath="url(#clip0_3509_10815)">
                <path
                    d="M18 9.50586C13.3031 9.50586 9.50625 13.3027 9.50625 17.9996C9.50625 22.6965 13.3031 26.5215 18 26.5215C22.6969 26.5215 26.5219 22.6965 26.5219 17.9996C26.5219 13.3027 22.6969 9.50586 18 9.50586ZM18 25.2559C14.0062 25.2559 10.7719 21.9934 10.7719 17.9996C10.7719 14.0059 14.0062 10.7715 18 10.7715C21.9937 10.7715 25.2562 14.034 25.2562 18.0277C25.2562 21.9934 21.9937 25.2559 18 25.2559Z"
                    fill="white"
                />
                <path
                    d="M20.5875 15.3842C20.3344 15.1311 19.9406 15.1311 19.6875 15.3842L18 17.0998L16.2844 15.3842C16.0313 15.1311 15.6375 15.1311 15.3844 15.3842C15.1313 15.6373 15.1313 16.0311 15.3844 16.2842L17.1 17.9998L15.3844 19.7154C15.1313 19.9686 15.1313 20.3623 15.3844 20.6154C15.4969 20.7279 15.6656 20.8123 15.8344 20.8123C16.0031 20.8123 16.1719 20.7561 16.2844 20.6154L18 18.8998L19.7156 20.6154C19.8281 20.7279 19.9969 20.8123 20.1656 20.8123C20.3344 20.8123 20.5031 20.7561 20.6156 20.6154C20.8688 20.3623 20.8688 19.9686 20.6156 19.7154L18.9 17.9998L20.6156 16.2842C20.8406 16.0311 20.8406 15.6373 20.5875 15.3842Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_3509_10815">
                    <rect width="18" height="18" fill="white" transform="translate(9 9)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ErrorSvg;
