import { type ReactElement } from 'react';
import { type SvgFillColorProps } from '@shared/svg-colors/svg-color.type.ts';

const CloseSvg = ({ className = '' }: SvgFillColorProps): ReactElement | null => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className={className}
        >
            <g clipPath="url(#clip0_7232_47311)">
                <path
                    d="M8.7999 7.9999L14.8999 1.8999C15.1249 1.6749 15.1249 1.3249 14.8999 1.0999C14.6749 0.874902 14.3249 0.874902 14.0999 1.0999L7.9999 7.1999L1.8999 1.0999C1.6749 0.874902 1.3249 0.874902 1.0999 1.0999C0.874902 1.3249 0.874902 1.6749 1.0999 1.8999L7.1999 7.9999L1.0999 14.0999C0.874902 14.3249 0.874902 14.6749 1.0999 14.8999C1.1999 14.9999 1.3499 15.0749 1.4999 15.0749C1.6499 15.0749 1.7999 15.0249 1.8999 14.8999L7.9999 8.7999L14.0999 14.8999C14.1999 14.9999 14.3499 15.0749 14.4999 15.0749C14.6499 15.0749 14.7999 15.0249 14.8999 14.8999C15.1249 14.6749 15.1249 14.3249 14.8999 14.0999L8.7999 7.9999Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath>
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CloseSvg;
