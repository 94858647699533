import { createSlice } from '@reduxjs/toolkit';
import { type StateListWoPagination } from '@store/state.types.ts';
import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateListWoPagination } from '@pages/dashboard/shared/crud/crud.reducers';

import { type Holiday } from './holidays.types';
import { HolidaysThunks } from './holidays.thunk';

export type HolidaysState = StateListWoPagination<Holiday>;

export const holidaysThunks = new HolidaysThunks();

const { stateKey, defaultThunks } = holidaysThunks;

const initialState = getInitialStateListWoPagination<Holiday>();

export const holidaysSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});

export default holidaysSlice.reducer;
