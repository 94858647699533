import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enBreadcrumbs from '@pages/dashboard/breadcrumbs/i18n/en.json';
import ukBreadcrumbs from '@pages/dashboard/breadcrumbs/i18n/uk.json';
import ruBreadcrumbs from '@pages/dashboard/breadcrumbs/i18n/ru.json';

export const breadcrumbsI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.BREADCRUMBS]: enBreadcrumbs,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.BREADCRUMBS]: ukBreadcrumbs,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.BREADCRUMBS]: ruBreadcrumbs,
    },
};
