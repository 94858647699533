import httpClient from '@shared/http/http.service';

export abstract class SingleApiService<T, Create, Update = Partial<Create>> {
    public constructor(public readonly prefix: string) {}

    public create(data: Create): Promise<void> {
        return httpClient.post(this.prefix, data);
    }

    public getOne(): Promise<T> {
        return httpClient.get(this.prefix);
    }

    public delete(): Promise<void> {
        return httpClient.delete(this.prefix);
    }

    public update(data: Update): Promise<void> {
        return httpClient.patch(this.prefix, data);
    }
}
