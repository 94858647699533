import { type ListAndCurrentApiService } from './list-and-current-api.service';
import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types';
import { createWithListThunk } from '@pages/dashboard/shared/crud/create.thunks';
import { deleteWithListThunk } from '@pages/dashboard/shared/crud/delete.thunks';
import { getCurrentThunk, getInsightsThunk, getListThunk } from '@pages/dashboard/shared/crud/get.thunks';
import { customUpdateThunk, updateCurrentWithListThunk } from '@pages/dashboard/shared/crud/update.thunks';
import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import { type StateKey } from '@store/state.types';
import { type ThunkCrudOrArray } from '../crud.types';
import { optionalFields } from '../../utils/utils';

export abstract class ListAndCurrentThunks<
    Service extends ListAndCurrentApiService<Item, List, Query, Create, Insights, Update>,
    Item extends { slug: string },
    List,
    Query,
    Create,
    Insights = any,
    Update = Partial<Create>,
> {
    public readonly getOneThunk;
    public readonly getAllThunk;
    public readonly createThunk;
    public readonly updateThunk;
    public readonly deleteThunk;
    public readonly getInsightsThunk;
    public readonly recoverThunk;
    public readonly archiveThunk;

    public constructor(
        public readonly service: Service,
        public readonly stateKey: StateKey,
        public readonly getName?: (data: Item) => string,
    ) {
        this.getOneThunk = getCurrentThunk<Item>({
            stateKey,
            serviceFunction: service.getOne.bind(service),
        });

        this.getAllThunk = getListThunk<Pagination<List>, Query>({
            stateKey,
            serviceFunction: service.getAll.bind(service),
        });

        this.createThunk = createWithListThunk<Create, Query, Item>({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.CREATED`,
            serviceFunction: service.create.bind(service),
            ...optionalFields({ getName }),
            thunksOnSuccess: (query) => {
                const thunks: ThunkCrudOrArray = [this.getAllThunk(query)];
                if (window?.location?.pathname?.includes?.('insights')) {
                    thunks.push(this.getInsightsThunk(query));
                }
                return thunks;
            },
        });

        this.updateThunk = updateCurrentWithListThunk<Update, Item, Query>({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.UPDATED`,
            serviceFunction: service.update.bind(service),
            ...optionalFields({ getName }),
            getOneThunk: this.getOneThunk,
            getItemsThunk: this.getAllThunk,
        });

        this.deleteThunk = deleteWithListThunk<Query, Item>({
            stateKey,
            successMessage: `${stateKey.toUpperCase()}.DELETED`,
            serviceFunction: service.delete.bind(service),
            ...optionalFields({ getName }),
            thunksOnSuccess: this.getAllThunk,
        });

        this.getInsightsThunk = getInsightsThunk<Insights, Query>({
            stateKey,
            serviceFunction: service.getInsights.bind(service),
        });

        this.recoverThunk = customUpdateThunk<{ id: number }, Query, Item>({
            stateKey,
            action: 'recover',
            serviceFunction: ({ id }) => service.recover.bind(service)(id),
            ...optionalFields({ getName }),
            getItemsThunk: this.getAllThunk,
            successMessage: `${stateKey.toUpperCase()}.RECOVERED`,
        });

        this.archiveThunk = customUpdateThunk<{ id: number }, Query, Item>({
            stateKey,
            action: 'archive',
            serviceFunction: ({ id }) => service.archive.bind(service)(id),
            ...optionalFields({ getName }),
            getItemsThunk: this.getAllThunk,
            successMessage: `${stateKey.toUpperCase()}.ARCHIVED`,
        });
    }

    public get defaultThunks(): ReturnType<typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>>[] {
        return [
            this.createThunk,
            this.getInsightsThunk,
            this.deleteThunk,
            this.getAllThunk,
            this.getOneThunk,
            this.updateThunk,
        ];
    }
}
