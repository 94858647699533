import { CommonRoutes } from '@libraries/routes/routes.enum';

export enum MainRoutes {
    HOME = '/',
    LOGIN = '/login',
    PROFILE = '/profile',
    SET_PASSWORD = '/set-password',
    FORGOT_PASSWORD = '/forgot-password',
    RESET_PASSWORD = '/reset-password',
    NOTIFICATIONS = '/notifications',
    TEAM = 'team',
    COMPANY = 'company',
    RECRUITING = 'recruiting',
    SALES = 'sales',
    CUSTOMERS = 'customers',
    SLUG = ':slug',
    NOT_FOUND = '/not-found',
    ALL = '*',
    INSIGHTS = 'insights',
}

export enum SalesRoutes {
    SALES_LEADS = `/${MainRoutes.SALES}/leads`,
}

export enum CustomersRoutes {
    CUSTOMERS_CLIENTS = `/${MainRoutes.CUSTOMERS}/clients`,
    CUSTOMERS_PROJECTS = `/${MainRoutes.CUSTOMERS}/projects`,
    CUSTOMERS_ATTACHMENTS = `/${MainRoutes.CUSTOMERS}/attachments`,
    CUSTOMERS_ATTACHMENTS_DOCUMENTS = `${CustomersRoutes.CUSTOMERS_ATTACHMENTS}/documents`,
    CUSTOMERS_ATTACHMENTS_LINKS = `${CustomersRoutes.CUSTOMERS_ATTACHMENTS}/links`,
    CUSTOMERS_ATTACHMENTS_CREDENTIALS = `${CustomersRoutes.CUSTOMERS_ATTACHMENTS}/credentials`,
}

export enum RecruitingRoutes {
    RECRUITING_OPEN_POSITIONS = `/${MainRoutes.RECRUITING}/open-positions`,
    RECRUITING_CANDIDATES = `/${MainRoutes.RECRUITING}/candidates`,
}

export enum TeamRoutes {
    TEAM_EMPLOYEES = `/${MainRoutes.TEAM}/employees`,
    TEAM_ATTACHMENTS = `/${MainRoutes.TEAM}/attachments`,
    TEAM_ATTACHMENTS_DOCUMENTS = `${TeamRoutes.TEAM_ATTACHMENTS}/documents`,
    TEAM_ATTACHMENTS_LINKS = `${TeamRoutes.TEAM_ATTACHMENTS}/links`,
    TEAM_ATTACHMENTS_CREDENTIALS = `${TeamRoutes.TEAM_ATTACHMENTS}/credentials`,
}

export enum CompanyRoutes {
    ACTIVITIES_LOG = `/${MainRoutes.COMPANY}/activities-log`,
    COMPANY_ATTACHMENTS = `/${MainRoutes.COMPANY}/attachments`,
    COMPANY_ATTACHMENTS_DOCUMENTS = `${CompanyRoutes.COMPANY_ATTACHMENTS}/documents`,
    COMPANY_ATTACHMENTS_LINKS = `${CompanyRoutes.COMPANY_ATTACHMENTS}/links`,
    COMPANY_ATTACHMENTS_CREDENTIALS = `${CompanyRoutes.COMPANY_ATTACHMENTS}/credentials`,
    COMPANY_BACKUPS = `/${MainRoutes.COMPANY}/backups`,
    COMPANY_INVENTORY = `/${MainRoutes.COMPANY}/inventory`,
    COMPANY_POSITIONS = `/${MainRoutes.COMPANY}/positions`,
    COMPANY_STRUCTURE = `/${MainRoutes.COMPANY}/structure`,
}

export enum SettingsRoutes {
    COMPANY_SETTINGS = CommonRoutes.COMPANY_SETTINGS,
    COMPANY_SETTINGS_GENERAL = `/${MainRoutes.COMPANY}/settings/general`,
    COMPANY_SETTINGS_VACATION_POLICY = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_VACATION_POLICY}`,
    COMPANY_SETTINGS_SICKNESS_POLICY = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_SICKNESS_POLICY}`,
    COMPANY_SETTINGS_DATABASE_BACKUP = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_DATABASE_BACKUP}`,
    COMPANY_SETTINGS_WORKING_WEEK = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_WORKING_WEEK}`,
    COMPANY_SETTINGS_HOLIDAYS = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_HOLIDAYS}`,
    COMPANY_SETTINGS_EMAIL = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_EMAIL}`,
    COMPANY_SETTINGS_STORAGE = `${CommonRoutes.COMPANY_SETTINGS}/${CommonRoutes.COMPANY_SETTINGS_STORAGE}`,
}

type Routes =
    | MainRoutes
    | CompanyRoutes
    | SettingsRoutes
    | TeamRoutes
    | RecruitingRoutes
    | CustomersRoutes
    | SalesRoutes;

const Routes = {
    ...MainRoutes,
    ...CompanyRoutes,
    ...SettingsRoutes,
    ...TeamRoutes,
    ...RecruitingRoutes,
    ...CustomersRoutes,
    ...SalesRoutes,
};

export default Routes;
