import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types.ts';
import { getListThunk } from '@pages/dashboard/shared/crud/get.thunks.ts';
import * as activitiesService from './activity-log.service';
import { type Activity, type ActivityQuery, type ActivityLogCharts } from './activity-log.types.ts';

export const activitiesGetType = 'activities/get';
export const activitiesChartsGetType = 'activities/charts/get';

export const getActivitiesThunk = getListThunk<Pagination<Activity>, ActivityQuery>({
    stateKey: 'activities',
    serviceFunction: activitiesService.getActivityLog,
});

export const getActivityChartsThunk = createAsyncThunkWrapper<ActivityLogCharts, ActivityQuery>({
    actionType: activitiesChartsGetType,
    serviceFunction: activitiesService.getActivityLogCharts,
});
