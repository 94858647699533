export enum ErrorKeys {
    TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_TOKEN = 'INVALID_TOKEN',
    ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
    FILE_NOT_FOUND = 'FILE_NOT_FOUND',
    ENTITY_ALREADY_EXIST = 'ENTITY_ALREADY_EXIST',
    DONT_HAVE_RIGHTS = 'DONT_HAVE_RIGHTS',
    STORAGE_IS_FULL = 'STORAGE_IS_FULL',
    CANT_DELETE_OWN_PROFILE = 'CANT_DELETE_OWN_PROFILE',
    INVALID_PERIOD = 'INVALID_PERIOD',
    POSITION_IS_DEFAULT = 'POSITION_IS_DEFAULT',
    DEFAULT_POSITION_CANT_BE_DELETED = 'DEFAULT_POSITION_CANT_BE_DELETED',
    SENDING_EMAIL_ERROR = 'SENDING_EMAIL_ERROR',
    VERIFICATION_EMAIL_ERROR = 'VERIFICATION_EMAIL_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
