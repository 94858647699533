import { type ReactElement } from 'react';

const WarningSvg = (): ReactElement => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#FBBF24" />
            <path
                d="M26.0156 20.6156L19.9969 10.9312C19.5188 10.2844 18.7875 9.91875 18 9.91875C17.1844 9.91875 16.4531 10.2844 16.0031 10.9312L9.98439 20.6156C9.42189 21.375 9.33752 22.3594 9.75939 23.2031C10.1813 24.0469 11.025 24.5812 11.9813 24.5812H24.0188C24.975 24.5812 25.8188 24.0469 26.2406 23.2031C26.6625 22.3875 26.5781 21.375 26.0156 20.6156ZM25.1156 22.6406C24.8906 23.0625 24.4969 23.3156 24.0188 23.3156H11.9813C11.5031 23.3156 11.1094 23.0625 10.8844 22.6406C10.6875 22.2187 10.7156 21.7406 10.9969 21.375L17.0156 11.6906C17.2406 11.3812 17.6063 11.1844 18 11.1844C18.3938 11.1844 18.7594 11.3531 18.9844 11.6906L25.0031 21.375C25.2844 21.7406 25.3125 22.2187 25.1156 22.6406Z"
                fill="white"
            />
            <path
                d="M17.9999 15.15C17.6624 15.15 17.353 15.4313 17.353 15.7969V18.8625C17.353 19.2 17.6343 19.5094 17.9999 19.5094C18.3655 19.5094 18.6468 19.2281 18.6468 18.8625V15.7688C18.6468 15.4313 18.3374 15.15 17.9999 15.15Z"
                fill="white"
            />
            <path
                d="M17.9999 20.25C17.6624 20.25 17.353 20.5313 17.353 20.8969V21.0375C17.353 21.375 17.6343 21.6844 17.9999 21.6844C18.3655 21.6844 18.6468 21.4031 18.6468 21.0375V20.8688C18.6468 20.5313 18.3374 20.25 17.9999 20.25Z"
                fill="white"
            />
        </svg>
    );
};

export default WarningSvg;
