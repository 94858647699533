import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enNotifications from '@pages/dashboard/pages/notifications/i18n/en.json';
import ukNotifications from '@pages/dashboard/pages/notifications/i18n/uk.json';
import ruNotifications from '@pages/dashboard/pages/notifications/i18n/ru.json';

export const notificationsI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: enNotifications,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: ukNotifications,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: ruNotifications,
    },
};
