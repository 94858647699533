import Routes from '@shared/routes.enum.ts';
import { type RouteConfiguration } from '@router/route.types.ts';
import {
    companyEntitiesHaveAttachments,
    customersEntitiesHaveAttachments,
    EntityType,
    teamEntitiesHaveAttachments,
} from '@libraries/entity-type/entity-type.enum';

import { Suspense, lazy } from 'react';
import type { UIMatch } from 'react-router-dom';

const InventoryTab = lazy(() => import('@inventory/inventory-item/inventory-tab/inventory-tab'));
const ClientTab = lazy(() => import('@pages/dashboard/pages/customers/clients/client-item/client-tab/client-tab'));
const CredentialsTab = lazy(() => import('@pages/dashboard/shared/entity-tabs/credentials-tab/credentials-tab'));
const DocumentsTab = lazy(() => import('@pages/dashboard/shared/entity-tabs/documents-tab/documents-tab'));
const LinksTab = lazy(() => import('@pages/dashboard/shared/entity-tabs/links-tab/links-tab'));
const ProjectTab = lazy(() => import('@pages/dashboard/pages/customers/projects/project-item/project-tab/project-tab'));
const EmployeeTab = lazy(() => import('@pages/dashboard/pages/team/employees/employee/employee-tab/employee-tab'));

export type MatchHandle = {
    breadcrumb?: string;
    breadcrumbSkip?: boolean;
    attachmentEntityType?: EntityType;
    attachmentResources?: EntityType[];
    entityType?: EntityType;
    htmlDescription: string;
    htmlKeywords: string;
    htmlTitle: string;
    insights?: boolean;
};

export type Match = UIMatch & {
    handle?: MatchHandle;
};

// TODO(xakeppok): maybe get routes from entity type, or add prefix team etc to entityType
const getAttachmentsTabs = (metaNamespace: string): RouteConfiguration[] => [
    {
        path: 'links',
        handle: {
            breadcrumb: 'LINKS',
            htmlTitle: `HTML_TITLE.${metaNamespace}`,
            htmlDescription: `HTML_DESCRIPTION.${metaNamespace}`,
            htmlKeywords: `HTML_KEYWORDS.${metaNamespace}`,
            attachmentEntityType: EntityType.LINKS,
        },
        element: (
            <Suspense>
                <LinksTab />
            </Suspense>
        ),
    },
    {
        path: 'documents',
        handle: {
            breadcrumb: 'DOCUMENTS',
            htmlTitle: `HTML_TITLE.${metaNamespace}`,
            htmlDescription: `HTML_DESCRIPTION.${metaNamespace}`,
            htmlKeywords: `HTML_KEYWORDS.${metaNamespace}`,
            attachmentEntityType: EntityType.DOCUMENTS,
        },
        element: (
            <Suspense>
                <DocumentsTab />
            </Suspense>
        ),
    },
    {
        path: 'credentials',
        handle: {
            breadcrumb: 'CREDENTIALS',
            htmlTitle: `HTML_TITLE.${metaNamespace}`,
            htmlDescription: `HTML_DESCRIPTION.${metaNamespace}`,
            htmlKeywords: `HTML_KEYWORDS.${metaNamespace}`,
            attachmentEntityType: EntityType.CREDENTIALS,
        },
        element: (
            <Suspense>
                <CredentialsTab />
            </Suspense>
        ),
    },
];

export type AttachmentRoutes = {
    main: string;
    documents: string;
    credentials: string;
    links: string;
    attachmentResources?: EntityType[];
};

const getAttachmentsRoutes = (routes: AttachmentRoutes): RouteConfiguration => {
    const { main, documents, credentials, links, attachmentResources = [] } = routes || {};
    return {
        path: main,
        handle: {
            htmlTitle: 'HTML_TITLE.ATTACHMENTS',
            htmlDescription: 'HTML_DESCRIPTION.ATTACHMENTS',
            htmlKeywords: 'HTML_KEYWORDS.ATTACHMENTS',
            breadcrumb: 'ATTACHMENTS',
            attachmentResources,
        },

        children: [
            {
                path: '',
                redirectTo: documents,
            },
            {
                path: documents,
                handle: {
                    breadcrumb: 'DOCUMENTS',
                    htmlTitle: 'HTML_TITLE.DOCUMENTS',
                    htmlDescription: 'HTML_DESCRIPTION.DOCUMENTS',
                    htmlKeywords: 'HTML_KEYWORDS.DOCUMENTS',
                },
                componentPath: 'dashboard/shared/attachments/documents/documents',
            },
            {
                path: links,
                handle: {
                    breadcrumb: 'LINKS',
                    htmlTitle: 'HTML_TITLE.LINKS',
                    htmlDescription: 'HTML_DESCRIPTION.LINKS',
                    htmlKeywords: 'HTML_KEYWORDS.LINKS',
                },
                componentPath: 'dashboard/shared/attachments/links/links',
            },
            {
                path: credentials,
                handle: {
                    breadcrumb: 'CREDENTIALS',
                    htmlTitle: 'HTML_TITLE.CREDENTIALS',
                    htmlDescription: 'HTML_DESCRIPTION.CREDENTIALS',
                    htmlKeywords: 'HTML_KEYWORDS.CREDENTIALS',
                },
                componentPath: 'dashboard/shared/attachments/credentials/credentials',
            },
        ],
    };
};

export const routesConfiguration: RouteConfiguration[] = [
    {
        path: Routes.HOME,
        handle: {
            breadcrumb: 'HOME',
            htmlTitle: 'HTML_TITLE.HOME',
            htmlDescription: 'HTML_DESCRIPTION.HOME',
            htmlKeywords: 'HTML_KEYWORDS.HOME',
        },
        componentPath: 'dashboard/dashboard',
        children: [
            {
                path: Routes.HOME,
                componentPath: 'dashboard/pages/calendar/calendar',
            },
            {
                path: Routes.NOTIFICATIONS,
                componentPath: 'dashboard/pages/notifications/notifications',
                handle: {
                    breadcrumb: 'NOTIFICATIONS',
                    htmlTitle: 'HTML_TITLE.NOTIFICATIONS',
                    htmlDescription: 'HTML_DESCRIPTION.NOTIFICATIONS',
                    htmlKeywords: 'HTML_KEYWORDS.NOTIFICATIONS',
                },
            },
            {
                path: Routes.CUSTOMERS_CLIENTS,
                handle: {
                    entityType: EntityType.CLIENTS,
                    breadcrumb: 'CLIENTS',
                    htmlTitle: 'HTML_TITLE.CLIENTS',
                    htmlDescription: 'HTML_DESCRIPTION.CLIENTS',
                    htmlKeywords: 'HTML_KEYWORDS.CLIENTS',
                },
                children: [
                    {
                        path: '',
                        componentPath: 'dashboard/pages/customers/clients/clients',
                    },
                    {
                        path: ':slug',
                        handle: {
                            htmlTitle: 'HTML_TITLE.CLIENT',
                            htmlDescription: 'HTML_DESCRIPTION.CLIENT',
                            htmlKeywords: 'HTML_KEYWORDS.CLIENT',
                        },
                        componentPath: 'dashboard/pages/customers/clients/client-item/client-item',
                        children: [
                            {
                                path: '',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlTitle: 'HTML_TITLE.CLIENT',
                                    htmlDescription: 'HTML_DESCRIPTION.CLIENT',
                                    htmlKeywords: 'HTML_KEYWORDS.CLIENT',
                                },
                                element: (
                                    <Suspense>
                                        <ClientTab />
                                    </Suspense>
                                ),
                            },
                            ...getAttachmentsTabs('CLIENT'),
                        ],
                    },
                ],
            },
            {
                path: Routes.CUSTOMERS_PROJECTS,
                handle: {
                    breadcrumb: 'PROJECTS',
                    entityType: EntityType.PROJECTS,
                    htmlTitle: 'HTML_TITLE.PROJECTS',
                    htmlDescription: 'HTML_DESCRIPTION.PROJECTS',
                    htmlKeywords: 'HTML_KEYWORDS.PROJECTS',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/customers/projects/projects' },
                    {
                        path: ':slug',
                        componentPath: 'dashboard/pages/customers/projects/project-item/project-item',
                        children: [
                            {
                                path: '',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlTitle: 'HTML_TITLE.PROJECT',
                                    htmlDescription: 'HTML_DESCRIPTION.PROJECT',
                                    htmlKeywords: 'HTML_KEYWORDS.PROJECT',
                                },
                                element: (
                                    <Suspense>
                                        <ProjectTab />
                                    </Suspense>
                                ),
                            },
                            ...getAttachmentsTabs('PROJECT'),
                        ],
                        handle: {
                            htmlTitle: 'HTML_TITLE.PROJECT',
                            htmlDescription: 'HTML_DESCRIPTION.PROJECT',
                            htmlKeywords: 'HTML_KEYWORDS.PROJECT',
                        },
                    },
                ],
            },
            {
                path: Routes.TEAM_EMPLOYEES,
                handle: {
                    entityType: EntityType.EMPLOYEES,
                    breadcrumb: 'EMPLOYEES',
                    htmlTitle: 'HTML_TITLE.EMPLOYEES',
                    htmlDescription: 'HTML_DESCRIPTION.EMPLOYEES',
                    htmlKeywords: 'HTML_KEYWORDS.EMPLOYEES',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/team/employees/employees' },
                    {
                        path: 'insights',
                        handle: {
                            insights: true,
                            htmlTitle: 'HTML_TITLE.EMPLOYEES_INSIGHTS',
                            htmlDescription: 'HTML_DESCRIPTION.EMPLOYEES_INSIGHTS',
                            htmlKeywords: 'HTML_KEYWORDS.EMPLOYEES_INSIGHTS',
                        },
                        componentPath: 'dashboard/pages/team/employees/employees',
                    },
                    {
                        path: ':slug',
                        handle: {
                            htmlTitle: 'HTML_TITLE.EMPLOYEE',
                            htmlDescription: 'HTML_DESCRIPTION.EMPLOYEE',
                            htmlKeywords: 'HTML_KEYWORDS.EMPLOYEE',
                        },
                        componentPath: 'dashboard/pages/team/employees/employee/employee',
                        element: (
                            <Suspense fallback={<div>loading...</div>}>
                                <EmployeeTab />
                            </Suspense>
                        ),
                        children: [
                            {
                                path: '',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlTitle: 'HTML_TITLE.EMPLOYEE',
                                    htmlDescription: 'HTML_DESCRIPTION.EMPLOYEE',
                                    htmlKeywords: 'HTML_KEYWORDS.EMPLOYEE',
                                },
                                element: (
                                    <Suspense>
                                        <EmployeeTab />
                                    </Suspense>
                                ),
                            },
                            ...getAttachmentsTabs('EMPLOYEE'),
                        ],
                    },
                ],
            },
            {
                path: Routes.PROFILE,
                handle: {
                    entityType: EntityType.EMPLOYEES,
                    breadcrumbSkip: true,
                    htmlTitle: 'HTML_TITLE.PROFILE',
                    htmlDescription: 'HTML_DESCRIPTION.PROFILE',
                    htmlKeywords: 'HTML_KEYWORDS.PROFILE',
                },
                children: [
                    {
                        path: ':slug',
                        handle: {
                            htmlTitle: 'HTML_TITLE.PROFILE',
                            htmlDescription: 'HTML_DESCRIPTION.PROFILE',
                            htmlKeywords: 'HTML_KEYWORDS.PROFILE',
                        },
                        componentPath: 'dashboard/pages/team/employees/employee/employee',
                        element: (
                            <Suspense fallback={<div>loading...</div>}>
                                <EmployeeTab />
                            </Suspense>
                        ),
                        children: [
                            {
                                path: '',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlDescription: 'HTML_DESCRIPTION.PROFILE',
                                    htmlKeywords: 'HTML_KEYWORDS.PROFILE',
                                    htmlTitle: 'HTML_TITLE.PROFILE',
                                },
                                element: (
                                    <Suspense>
                                        <EmployeeTab />
                                    </Suspense>
                                ),
                            },
                            ...getAttachmentsTabs('PROFILE'),
                        ],
                    },
                ],
            },
            {
                path: Routes.COMPANY_INVENTORY,
                handle: {
                    entityType: EntityType.INVENTORY,
                    breadcrumb: 'INVENTORY',
                    htmlTitle: 'HTML_TITLE.INVENTORY',
                    htmlDescription: 'HTML_DESCRIPTION.INVENTORY',
                    htmlKeywords: 'HTML_KEYWORDS.INVENTORY',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/company/inventory/inventory' },
                    {
                        path: ':slug',
                        componentPath: 'dashboard/pages/company/inventory/inventory-item/inventory-item',
                        children: [
                            {
                                path: '',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlTitle: 'HTML_TITLE.INVENTORY_RECORD',
                                    htmlDescription: 'HTML_DESCRIPTION.INVENTORY_RECORD',
                                    htmlKeywords: 'HTML_KEYWORDS.INVENTORY_RECORD',
                                },
                                element: (
                                    <Suspense>
                                        <InventoryTab />
                                    </Suspense>
                                ),
                            },
                            ...getAttachmentsTabs('INVENTORY_RECORD'),
                        ],
                    },
                ],
            },
            {
                path: Routes.COMPANY_BACKUPS,
                handle: {
                    breadcrumb: 'BACKUPS',
                    htmlTitle: 'HTML_TITLE.BACKUPS',
                    htmlDescription: 'HTML_DESCRIPTION.BACKUPS',
                    htmlKeywords: 'HTML_KEYWORDS.BACKUPS',
                },
                componentPath: 'dashboard/pages/company/backups/backups',
            },
            {
                path: Routes.COMPANY_SETTINGS,
                handle: {
                    breadcrumb: 'SETTINGS',
                    htmlTitle: 'HTML_TITLE.SETTINGS',
                    htmlDescription: 'HTML_DESCRIPTION.SETTINGS',
                    htmlKeywords: 'HTML_KEYWORDS.SETTINGS',
                },
                children: [
                    {
                        path: '',
                        componentPath: 'dashboard/pages/company/settings/settings',
                        children: [
                            {
                                path: '',
                                redirectTo: Routes.COMPANY_SETTINGS_GENERAL,
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_GENERAL,
                                handle: {
                                    breadcrumb: 'GENERAL',
                                    htmlTitle: 'HTML_TITLE.GENERAL',
                                    htmlDescription: 'HTML_DESCRIPTION.GENERAL',
                                    htmlKeywords: 'HTML_KEYWORDS.GENERAL',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/general/general-settings',
                            },

                            {
                                path: Routes.COMPANY_SETTINGS_EMAIL,
                                handle: {
                                    breadcrumb: 'MAILBOX',
                                    htmlTitle: 'HTML_TITLE.MAILBOX',
                                    htmlDescription: 'HTML_DESCRIPTION.MAILBOX',
                                    htmlKeywords: 'HTML_KEYWORDS.MAILBOX',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/mail-box/mail-box',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_VACATION_POLICY,
                                handle: {
                                    breadcrumb: 'VACATION_POLICY',
                                    htmlTitle: 'HTML_TITLE.VACATION_POLICY',
                                    htmlDescription: 'HTML_DESCRIPTION.VACATION_POLICY',
                                    htmlKeywords: 'HTML_KEYWORDS.VACATION_POLICY',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/vacation-policy/vacation-policy',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_SICKNESS_POLICY,
                                handle: {
                                    breadcrumb: 'SICKNESS_POLICY',
                                    htmlTitle: 'HTML_TITLE.SICKNESS_POLICY',
                                    htmlDescription: 'HTML_DESCRIPTION.SICKNESS_POLICY',
                                    htmlKeywords: 'HTML_KEYWORDS.SICKNESS_POLICY',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/sickness-policy/sickness-policy',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_HOLIDAYS,
                                handle: {
                                    breadcrumb: 'HOLIDAYS',
                                    htmlTitle: 'HTML_TITLE.HOLIDAYS',
                                    htmlDescription: 'HTML_DESCRIPTION.HOLIDAYS',
                                    htmlKeywords: 'HTML_KEYWORDS.HOLIDAYS',
                                },
                                componentPath:
                                    'dashboard/pages/company/settings/pages/holidays-settings/holidays-settings',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_DATABASE_BACKUP,
                                handle: {
                                    breadcrumb: 'DATABASE_BACKUP',
                                    htmlTitle: 'HTML_TITLE.DATABASE_BACKUP',
                                    htmlDescription: 'HTML_DESCRIPTION.DATABASE_BACKUP',
                                    htmlKeywords: 'HTML_KEYWORDS.DATABASE_BACKUP',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/database-backup/database-backup',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_WORKING_WEEK,
                                handle: {
                                    breadcrumb: 'WORKING_WEEK',
                                    htmlTitle: 'HTML_TITLE.WORKING_WEEK',
                                    htmlDescription: 'HTML_DESCRIPTION.WORKING_WEEK',
                                    htmlKeywords: 'HTML_KEYWORDS.WORKING_WEEK',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/working-week/working-week',
                            },
                            {
                                path: Routes.COMPANY_SETTINGS_STORAGE,
                                handle: {
                                    breadcrumb: 'STORAGE',
                                    htmlTitle: 'HTML_TITLE.STORAGE',
                                    htmlDescription: 'HTML_DESCRIPTION.STORAGE',
                                    htmlKeywords: 'HTML_KEYWORDS.STORAGE',
                                },
                                componentPath: 'dashboard/pages/company/settings/pages/storage/storage',
                            },
                        ],
                    },
                ],
            },
            {
                path: Routes.COMPANY_POSITIONS,
                handle: {
                    entityType: EntityType.POSITIONS,
                    breadcrumb: 'POSITIONS',
                    htmlTitle: 'HTML_TITLE.POSITIONS',
                    htmlDescription: 'HTML_DESCRIPTION.POSITIONS',
                    htmlKeywords: 'HTML_KEYWORDS.POSITIONS',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/company/positions/positions' },
                    {
                        path: 'insights',
                        handle: {
                            insights: true,
                            htmlTitle: 'HTML_TITLE.POSITIONS_INSIGHTS',
                            htmlDescription: 'HTML_DESCRIPTION.POSITIONS_INSIGHTS',
                            htmlKeywords: 'HTML_KEYWORDS.POSITIONS_INSIGHTS',
                        },
                        componentPath: 'dashboard/pages/company/positions/positions',
                    },
                    {
                        path: ':slug',
                        componentPath: 'dashboard/pages/company/positions/position-item/position-item',
                        children: [
                            {
                                path: '',
                                componentPath:
                                    'dashboard/pages/company/positions/position-item/position-tab/position-tab',
                                handle: {
                                    breadcrumbSkip: true,
                                    htmlTitle: 'HTML_TITLE.POSITION',
                                    htmlDescription: 'HTML_DESCRIPTION.POSITION',
                                    htmlKeywords: 'HTML_KEYWORDS.POSITION',
                                },
                            },
                            ...getAttachmentsTabs('POSITION'),
                        ],
                    },
                ],
            },
            getAttachmentsRoutes({
                main: Routes.COMPANY_ATTACHMENTS,
                documents: Routes.COMPANY_ATTACHMENTS_DOCUMENTS,
                credentials: Routes.COMPANY_ATTACHMENTS_CREDENTIALS,
                links: Routes.COMPANY_ATTACHMENTS_LINKS,
                attachmentResources: companyEntitiesHaveAttachments,
            }),
            getAttachmentsRoutes({
                main: Routes.TEAM_ATTACHMENTS,
                documents: Routes.TEAM_ATTACHMENTS_DOCUMENTS,
                credentials: Routes.TEAM_ATTACHMENTS_CREDENTIALS,
                links: Routes.TEAM_ATTACHMENTS_LINKS,
                attachmentResources: teamEntitiesHaveAttachments,
            }),
            getAttachmentsRoutes({
                main: Routes.CUSTOMERS_ATTACHMENTS,
                documents: Routes.CUSTOMERS_ATTACHMENTS_DOCUMENTS,
                credentials: Routes.CUSTOMERS_ATTACHMENTS_CREDENTIALS,
                links: Routes.CUSTOMERS_ATTACHMENTS_LINKS,
                attachmentResources: customersEntitiesHaveAttachments,
            }),
            {
                path: Routes.COMPANY_STRUCTURE,
                handle: {
                    breadcrumb: 'STRUCTURE',
                    htmlTitle: 'HTML_TITLE.STRUCTURE',
                    htmlDescription: 'HTML_DESCRIPTION.STRUCTURE',
                    htmlKeywords: 'HTML_KEYWORDS.STRUCTURE',
                },
                children: [{ path: '', componentPath: 'dashboard/pages/company/structure/structure' }],
            },
            {
                path: Routes.RECRUITING_OPEN_POSITIONS,
                soon: true,
                handle: {
                    breadcrumb: 'OPEN_POSITIONS',
                    htmlTitle: 'HTML_TITLE.OPEN_POSITIONS',
                    htmlDescription: 'HTML_DESCRIPTION.OPEN_POSITIONS',
                    htmlKeywords: 'HTML_KEYWORDS.OPEN_POSITIONS',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/recruiting/open-positions/open-positions' },
                    {
                        path: ':slug',
                        handle: {
                            htmlTitle: 'HTML_TITLE.OPEN_POSITION',
                            htmlDescription: 'HTML_DESCRIPTION.OPEN_POSITION',
                            htmlKeywords: 'HTML_KEYWORDS.OPEN_POSITION',
                        },
                        componentPath: 'dashboard/pages/recruiting/open-positions/open-position/open-position',
                    },
                ],
            },
            {
                path: Routes.RECRUITING_CANDIDATES,
                soon: true,
                handle: {
                    breadcrumb: 'CANDIDATES',
                    htmlTitle: 'HTML_TITLE.CANDIDATES',
                    htmlDescription: 'HTML_DESCRIPTION.CANDIDATES',
                    htmlKeywords: 'HTML_KEYWORDS.CANDIDATES',
                },
                children: [
                    { path: '', componentPath: 'dashboard/pages/recruiting/candidates/candidates' },
                    {
                        path: ':slug',
                        handle: {
                            htmlTitle: 'HTML_TITLE.CANDIDATE',
                            htmlDescription: 'HTML_DESCRIPTION.CANDIDATE',
                            htmlKeywords: 'HTML_KEYWORDS.CANDIDATE',
                        },
                        componentPath: 'dashboard/pages/recruiting/candidates/candidate/candidate',
                    },
                ],
            },
            {
                path: Routes.SALES_LEADS,
                soon: true,
            },
            {
                path: Routes.ACTIVITIES_LOG,
                handle: {
                    breadcrumb: 'ACTIVITIES',
                    htmlTitle: 'HTML_TITLE.ACTIVITIES',
                    htmlDescription: 'HTML_DESCRIPTION.ACTIVITIES',
                    htmlKeywords: 'HTML_KEYWORDS.ACTIVITIES',
                },
                componentPath: 'dashboard/pages/activity-log/activity-log',
            },
            {
                path: Routes.ALL,
                handle: {
                    breadcrumb: '404',
                    htmlTitle: 'HTML_TITLE.404',
                    htmlDescription: 'HTML_DESCRIPTION.404',
                    htmlKeywords: 'HTML_KEYWORDS.404',
                },
                componentPath: 'not-found/not-found',
            },
        ],
    },
    {
        path: Routes.LOGIN,
        handle: {
            breadcrumb: 'LOGIN',
            htmlTitle: 'HTML_TITLE.LOGIN',
            htmlDescription: 'HTML_DESCRIPTION.LOGIN',
            htmlKeywords: 'HTML_KEYWORDS.LOGIN',
        },
        componentPath: 'auth/sign-in/sign-in',
    },
    {
        path: Routes.FORGOT_PASSWORD,
        handle: {
            breadcrumb: 'FORGOT_PASSWORD',
            htmlTitle: 'HTML_TITLE.FORGOT_PASSWORD',
            htmlDescription: 'HTML_DESCRIPTION.FORGOT_PASSWORD',
            htmlKeywords: 'HTML_KEYWORDS.FORGOT_PASSWORD',
        },
        componentPath: 'auth/forgot-password/forgot-password',
    },
    {
        path: Routes.SET_PASSWORD,
        handle: {
            breadcrumb: 'SET_PASSWORD',
            htmlTitle: 'HTML_TITLE.SET_PASSWORD',
            htmlDescription: 'HTML_DESCRIPTION.SET_PASSWORD',
            htmlKeywords: 'HTML_KEYWORDS.SET_PASSWORD',
        },
        componentPath: 'auth/set-password/set-password',
    },
];
