import { createSlice } from '@reduxjs/toolkit';
import { getEntityTabsCountsThunk } from './entity-tabs.thunk.ts';
import { type EntityTabsCount } from './entity-tabs.types.ts';

export type EntityTabsState = {
    counts: EntityTabsCount;
};

const initialState: EntityTabsState = {
    counts: { documents: 0, links: 0, credentials: 0 },
};

const stateKey = 'entityTabs';

export const entityCountsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        clearEntityCount() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEntityTabsCountsThunk.fulfilled, (state, { payload }) => {
            state.counts = payload;
        });
    },
});
export const { clearEntityCount } = entityCountsSlice.actions;

export default entityCountsSlice.reducer;
