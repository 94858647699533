import { createSingleThunk } from '@pages/dashboard/shared/crud/create.thunks.ts';
import { updateSingleThunk } from '@pages/dashboard/shared/crud/update.thunks';
import { type CreateHolidaysSchedule, type HolidaysSchedule } from './holidays-schedule.types';
import { type HolidaysFilterQuery } from '../../holidays/store/holidays.types';
import { holidaysThunks } from '../../holidays/store/holidays.slice';
import { SingleThunks } from '@pages/dashboard/shared/crud/single/single-thunks';
import settingsHolidaysScheduleService, { type SettingsHolidaysScheduleService } from './holidays-schedule.service';
import { type AppState } from '@store/store';

export class SettingsHolidaysScheduleThunks extends SingleThunks<
    SettingsHolidaysScheduleService,
    HolidaysSchedule,
    CreateHolidaysSchedule
> {
    public constructor() {
        super(settingsHolidaysScheduleService, 'settings.holidaysSchedule');

        this.createThunk = createSingleThunk<CreateHolidaysSchedule>({
            stateKey: this.stateKey,
            successMessage: `${this.successMessagePrefix}.CREATED`,
            serviceFunction: this.service.create.bind(this.service),
            thunksOnSuccess: this.getThunksOnSuccess.bind(this),
        });

        this.updateThunk = updateSingleThunk<Partial<CreateHolidaysSchedule>>({
            stateKey: this.stateKey,
            successMessage: `${this.successMessagePrefix}.UPDATED`,
            serviceFunction: this.service.update.bind(this.service),
            thunksOnSuccess: this.getThunksOnSuccess.bind(this),
        });
    }

    private getThunksOnSuccess(state: AppState) {
        return [this.getOneThunk(), holidaysThunks.getAllThunk(state.holidays.query as HolidaysFilterQuery)];
    }
}
