import { ListAndCurrentApiService } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-api.service';
import httpClient from '@shared/http/http.service';
import {
    type CreateEmployee,
    type Employee,
    type EmployeeInsights,
    type EmployeeList,
    type EmployeesFilterQuery,
} from './employees.types';
import { serializeDatesInObject } from '@shared/serializer/date-body.serializer';

export class EmployeesService extends ListAndCurrentApiService<
    Employee,
    EmployeeList,
    EmployeesFilterQuery,
    CreateEmployee,
    EmployeeInsights
> {
    public constructor() {
        super('employees');
    }

    public override update(id: number, data: Partial<CreateEmployee>): Promise<Employee> {
        return httpClient.patch(`${this.prefix}/${id}`, serializeDatesInObject(data));
    }

    public override create(data: CreateEmployee): Promise<Employee> {
        return httpClient.post(this.prefix, serializeDatesInObject(data));
    }

    public updateCurrent(data: Partial<CreateEmployee>): Promise<void> {
        return httpClient.patch(`${this.prefix}`, data);
    }

    public changePhoto(photo: File): Promise<void> {
        const formData = new FormData();
        formData.append('photo', photo);
        return httpClient.patch(`${this.prefix}/photo`, formData);
    }
}

const employeesService = new EmployeesService();
export default employeesService;
