import { clearCurrentState, clearUpcoming } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { type CurrentUpcoming } from '../../../shared/absence-policies/absence-policies.types';
import { SettingsSicknessPolicyThunks } from './sickness-policy.thunk';
import { type SicknessPolicy } from './sickness-policy.types';
export type SettingsSicknessPolicyState = StateSingle<CurrentUpcoming<SicknessPolicy>>;

const initialState: SettingsSicknessPolicyState = getInitialStateSingle();

export const settingsSicknessPolicyThunks = new SettingsSicknessPolicyThunks();

const { stateKey, defaultPlaningThunks } = settingsSicknessPolicyThunks;

export const sicknessPolicySettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultPlaningThunks, [clearCurrentState], (builder) => {
        // TODO(xakeppok): maybe add to common reducers?
        builder.addCase(clearUpcoming, (state) => {
            if (state.current) {
                state.current.upcoming = null;
            }
        });
    }),
});
export default sicknessPolicySettingsSlice.reducer;
