import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateList } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateList } from '@store/state.types.ts';
import { deleteBackupThunk, getBackupsThunk } from './backups.thunk';
import { type BackupList } from './backups.types.ts';

export type BackupsState = StateList<BackupList>;

export const backupsSlice = createSlice({
    name: 'backups',
    initialState: getInitialStateList<BackupList>(),
    reducers: {},
    extraReducers: createAsyncReducer([getBackupsThunk, deleteBackupThunk], [clearCurrentState]),
});

export default backupsSlice.reducer;
