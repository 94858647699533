import { type ReactElement } from 'react';
import LoaderSvg from '@shared/loader-svg/loader-svg.tsx';

const Loader = (): ReactElement | null => {
    return (
        <div className="absolute inset-0 flex items-center justify-center">
            <LoaderSvg className="size-16px" />
        </div>
    );
};

export default Loader;
