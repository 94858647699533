import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enErrorBoundary from '@pages/error-boundary/i18n/en.json';
import ukErrorBoundary from '@pages/error-boundary/i18n/uk.json';
import ruErrorBoundary from '@pages/error-boundary/i18n/ru.json';

export const errorBoundaryI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.ERROR_BOUNDARY]: enErrorBoundary,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.ERROR_BOUNDARY]: ukErrorBoundary,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.ERROR_BOUNDARY]: ruErrorBoundary,
    },
};
