import { type ReactElement } from 'react';
import { type ToastType } from '@shared/toast/store/toast.thunk.ts';
import ErrorSvg from '@shared/type-svg/error.tsx';
import InfoSvg from '@shared/type-svg/info.tsx';
import SuccessSvg from '@shared/type-svg/success.tsx';
import WarningSvg from '@shared/type-svg/warning.tsx';

type TypeSvg = {
    type: ToastType;
};
const TypeSvg = ({ type }: TypeSvg): ReactElement | null => {
    let typeSvg;

    switch (type) {
        case 'warning':
            typeSvg = <WarningSvg />;
            break;
        case 'info':
            typeSvg = <InfoSvg />;
            break;
        case 'error':
            typeSvg = <ErrorSvg />;
            break;
        default:
            typeSvg = <SuccessSvg />;
            break;
    }

    return <div className="size-36px">{typeSvg}</div>;
};

export default TypeSvg;
