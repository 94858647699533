import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enConfirmModal from '@shared/confirm-modal/i18n/en.json';
import ukConfirmModal from '@shared/confirm-modal/i18n/uk.json';
import ruConfirmModal from '@shared/confirm-modal/i18n/ru.json';
import enCopyToClipboard from '@shared/copy-to-clipboard/i18n/en.json';
import ukCopyToClipboard from '@shared/copy-to-clipboard/i18n/uk.json';
import ruCopyToClipboard from '@shared/copy-to-clipboard/i18n/ru.json';
import enDocumentTitleWithMeta from '@shared/document-title-with-meta/i18n/en.json';
import ukDocumentTitleWithMeta from '@shared/document-title-with-meta/i18n/uk.json';
import ruDocumentTitleWithMeta from '@shared/document-title-with-meta/i18n/ru.json';
import enErrorPageWrapper from '@shared/error-page-wrapper/i18n/en.json';
import ukErrorPageWrapper from '@shared/error-page-wrapper/i18n/uk.json';
import ruErrorPageWrapper from '@shared/error-page-wrapper/i18n/ru.json';
import enFooter from '@shared/footer/i18n/en.json';
import ukFooter from '@shared/footer/i18n/uk.json';
import ruFooter from '@shared/footer/i18n/ru.json';
import enFormatBytes from '@shared/format-bytes/i18n/en.json';
import ukFormatBytes from '@shared/format-bytes/i18n/uk.json';
import ruFormatBytes from '@shared/format-bytes/i18n/ru.json';
import enThemeSwitcher from '@shared/theme-switcher/i18n/en.json';
import ukThemeSwitcher from '@shared/theme-switcher/i18n/uk.json';
import ruThemeSwitcher from '@shared/theme-switcher/i18n/ru.json';
import enToast from '@shared/toast/i18n/en.json';
import { errorLocales } from '@libraries/error-response/error-locales';
import ukToast from '@shared/toast/i18n/uk.json';
import ruToast from '@shared/toast/i18n/ru.json';
import enTooltip from '@shared/tooltip/i18n/en.json';
import ukTooltip from '@shared/tooltip/i18n/uk.json';
import ruTooltip from '@shared/tooltip/i18n/ru.json';

export const sharedI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.CONFIRM_MODAL]: enConfirmModal,
        [TranslationsNameSpaces.COPY_TO_CLIPBOARD]: enCopyToClipboard,
        [TranslationsNameSpaces.DOCUMENT_TITLE_WITH_META]: enDocumentTitleWithMeta,
        [TranslationsNameSpaces.ERROR_PAGE_WRAPPER]: enErrorPageWrapper,
        [TranslationsNameSpaces.FOOTER]: enFooter,
        [TranslationsNameSpaces.FORMAT_BYTES]: enFormatBytes,
        [TranslationsNameSpaces.THEME_SWITCHER]: enThemeSwitcher,
        [TranslationsNameSpaces.TOAST]: { ...enToast, ERRORS: errorLocales.en },
        [TranslationsNameSpaces.TOOLTIP]: enTooltip,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.CONFIRM_MODAL]: ukConfirmModal,
        [TranslationsNameSpaces.COPY_TO_CLIPBOARD]: ukCopyToClipboard,
        [TranslationsNameSpaces.DOCUMENT_TITLE_WITH_META]: ukDocumentTitleWithMeta,
        [TranslationsNameSpaces.ERROR_PAGE_WRAPPER]: ukErrorPageWrapper,
        [TranslationsNameSpaces.FOOTER]: ukFooter,
        [TranslationsNameSpaces.FORMAT_BYTES]: ukFormatBytes,
        [TranslationsNameSpaces.THEME_SWITCHER]: ukThemeSwitcher,
        [TranslationsNameSpaces.TOAST]: { ...ukToast, ERRORS: errorLocales.uk },
        [TranslationsNameSpaces.TOOLTIP]: ukTooltip,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.CONFIRM_MODAL]: ruConfirmModal,
        [TranslationsNameSpaces.COPY_TO_CLIPBOARD]: ruCopyToClipboard,
        [TranslationsNameSpaces.DOCUMENT_TITLE_WITH_META]: ruDocumentTitleWithMeta,
        [TranslationsNameSpaces.ERROR_PAGE_WRAPPER]: ruErrorPageWrapper,
        [TranslationsNameSpaces.FOOTER]: ruFooter,
        [TranslationsNameSpaces.FORMAT_BYTES]: ruFormatBytes,
        [TranslationsNameSpaces.THEME_SWITCHER]: ruThemeSwitcher,
        [TranslationsNameSpaces.TOAST]: { ...ruToast, ERRORS: errorLocales.ru },
        [TranslationsNameSpaces.TOOLTIP]: ruTooltip,
    },
};
