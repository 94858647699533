import { startTransition, useState } from 'react';

export function useLocalStorage(key: LocalStorageKey, defaultValue?: string): [string, (value: string) => void] {
    const storedValue = localStorage.getItem(key);
    const [value, setValue] = useState(storedValue ?? defaultValue ?? '');

    const setStoredValue = (newValue: string): void => {
        startTransition(() => {
            setValue(newValue);
            localStorage.setItem(key, newValue);
        });
    };

    return [value, setStoredValue];
}

export const enum LocalStorageKey {
    THEME = 'theme',
    LANGUAGE = 'language',
    LAST_ROUTE = 'lastRoute',
}
