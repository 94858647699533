import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import { createAsyncThunkWrapper, handleAxiosError } from '@shared/store/store.utils';
import { type BasicThunkOptions, type ServiceFunction } from './crud.types';

type GetListThunkOptions<PaginationList, QueryFilter> = BasicThunkOptions<ServiceFunction<QueryFilter, PaginationList>>;

export const getInsightsThunk = <Insights, QueryFilter>({
    serviceFunction,
    stateKey,
}: GetListThunkOptions<Insights, QueryFilter>): ReturnType<
    typeof createAsyncThunk<Insights, QueryFilter, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<Insights, QueryFilter>({
        actionType: `${stateKey}/get-insights`,
        serviceFunction,
    });
};

export const getListThunk = <PaginationList, QueryFilter>({
    serviceFunction,
    stateKey,
}: GetListThunkOptions<PaginationList, QueryFilter>): ReturnType<
    typeof createAsyncThunk<PaginationList, QueryFilter, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<PaginationList, QueryFilter>({
        actionType: `${stateKey}/get-all`,
        serviceFunction,
    });
};

type GetCurrentThunkOptions<Item, Return = string> = BasicThunkOptions<ServiceFunction<Return, Item>>;

export const getCurrentThunk = <Item, Return = string>({
    stateKey,
    serviceFunction,
}: GetCurrentThunkOptions<Item, Return>): ReturnType<
    typeof createAsyncThunk<Item, Return, { rejectValue: UnknownAction | null }>
> => {
    return createAsyncThunkWrapper<Item, Return>({
        actionType: `${stateKey}/get-one`,
        errorCallback: (error, dispatch) => {
            if (error?.response?.status === 404) {
                return;
            }
            return handleAxiosError(error, dispatch);
        },
        serviceFunction,
    });
};
