import { createSingleThunk } from '@pages/dashboard/shared/crud/create.thunks';
import { deleteSingleThunk } from '@pages/dashboard/shared/crud/delete.thunks';
import { getCurrentThunk } from '@pages/dashboard/shared/crud/get.thunks';
import { updateSingleThunk } from '@pages/dashboard/shared/crud/update.thunks';
import { type createAsyncThunk, type UnknownAction } from '@reduxjs/toolkit';
import { type SingleApiService } from './single-api.service';
import { type StateKey } from '@store/state.types';

export abstract class SingleThunks<
    Service extends SingleApiService<T, Create>,
    T,
    Create,
    Update extends object = Partial<Create>,
> {
    public getOneThunk;
    public createThunk;
    public updateThunk;
    public deleteThunk;

    public constructor(
        public readonly service: Service,
        public readonly stateKey: StateKey,
    ) {
        this.getOneThunk = getCurrentThunk<T, void>({
            stateKey,
            serviceFunction: service.getOne.bind(service),
        });

        this.createThunk = createSingleThunk<Create>({
            stateKey,
            successMessage: `${this.successMessagePrefix}.CREATED`,
            serviceFunction: service.create.bind(service),
            thunksOnSuccess: this.getOneThunk,
        });

        this.updateThunk = updateSingleThunk<Update>({
            stateKey,
            successMessage: `${this.successMessagePrefix}.UPDATED`,
            serviceFunction: service.update.bind(service),
            thunksOnSuccess: this.getOneThunk,
        });

        this.deleteThunk = deleteSingleThunk({
            stateKey,
            successMessage: `${this.successMessagePrefix}.DELETED`,
            serviceFunction: service.delete.bind(service),
        });
    }

    public get defaultThunks(): ReturnType<typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>>[] {
        return [this.createThunk, this.deleteThunk, this.getOneThunk, this.updateThunk];
    }

    public get successMessagePrefix(): string {
        return this.stateKey
            .replace(/([A-Z])/g, '_$1')
            .replace(/\./g, '_')
            .toUpperCase();
    }

    public get defaultPlaningThunks(): ReturnType<
        typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>
    >[] {
        return [this.deleteThunk, this.getOneThunk, this.createThunk];
    }
}
