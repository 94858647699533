import { type CreateProject, type Project, type ProjectList, type ProjectsFilterQuery } from './projects.types.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { showToast } from '@shared/toast/store/toast.thunk.ts';
import { ListAndCurrentThunks } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-thunks.ts';
import projectsService, { type ProjectsService } from './projects.service.ts';
import { type ProjectsState } from './projects.slice.ts';
import { type DispatchType } from '@store/state.types.ts';

type ReopenFinish = {
    id: number;
    getAll: boolean;
};

export class ProjectsThunks extends ListAndCurrentThunks<
    ProjectsService,
    Project,
    ProjectList,
    ProjectsFilterQuery,
    CreateProject
> {
    public readonly finishThunk;
    public readonly reopenThunk;
    public constructor() {
        super(projectsService, 'projects', (project) => project.name);
        this.finishThunk = this.createProjectThunk(
            'finish',
            (id) => projectsService.finishProject(id),
            'PROJECTS.FINISHED',
        );
        this.reopenThunk = this.createProjectThunk(
            'reopen',
            (id) => projectsService.reopenProject(id),
            'PROJECTS.REOPENED',
        );
    }
    private createProjectThunk(
        actionTypeSuffix: string,
        serviceFunction: (id: number) => Promise<void>,
        successMessage: string,
    ): ReturnType<typeof createAsyncThunkWrapper<void, ReopenFinish>> {
        return createAsyncThunkWrapper<void, ReopenFinish>({
            actionType: `${this.stateKey}/${actionTypeSuffix}`,
            serviceFunction: async ({ id, getAll }, { dispatch }, state) => {
                await serviceFunction(id);
                this.getOneOrMany(getAll, state.projects, dispatch);
                dispatch(showToast({ type: 'success', message: successMessage }));
            },
        });
    }

    private getOneOrMany(getAll: boolean, { query, current }: ProjectsState, dispatch: DispatchType): void {
        if (getAll) {
            dispatch(this.getAllThunk(query));
            return;
        }
        if (current) {
            dispatch(this.getOneThunk(current.slug));
        }
    }
}
