import { type Create } from '@shared/create/create.types.ts';
import httpClient from '@shared/http/http.service.ts';
import { type MailBox } from './mail-box.types.ts';
import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service.ts';

const PREFIX = 'mail-box';

export class SettingsMailBoxService extends SingleApiService<MailBox, Create<MailBox>> {
    public constructor() {
        super('mail-box');
    }

    public sendTestEmail(email: string): Promise<void> {
        return httpClient.post(`${PREFIX}/send-test`, { email }, { timeout: 30000 });
    }
}
const settingsMailBoxService = new SettingsMailBoxService();
export default settingsMailBoxService;
