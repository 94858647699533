import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { clearCurrentAction, clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { getStorageThunk } from './storage.thunk';
import { type StorageType } from './storage.types';

export type SettingsStorageState = StateSingle<StorageType>;

const initialState: SettingsStorageState = getInitialStateSingle<StorageType>();

export const storageSettingsSlice = createSlice({
    name: 'settings.storage',
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer([getStorageThunk], [clearCurrentAction, clearCurrentState]),
});

export default storageSettingsSlice.reducer;
