import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListWoPagination } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListWoPagination } from '@store/state.types.ts';
import { getStructureThunk } from './structure.thunk.ts';
import type { Structure } from './structure.types.ts';

export type StructureState = StateListWoPagination<Structure>;

export const structureSlice = createSlice({
    name: 'structure',
    initialState: getInitialStateListWoPagination<Structure>(),
    reducers: {},
    extraReducers: createAsyncReducer([getStructureThunk], [clearCurrentState]),
});

export default structureSlice.reducer;
