import { createSlice } from '@reduxjs/toolkit';
import { getBackendVersionThunk, getNotAuthGeneralSettingsThunk } from './app-settings.thunk';
import type { GeneralSettings } from '@pages/dashboard/pages/company/settings/pages/general/store/general-settings.types';

export type AppSettingsState = {
    frontend: string;
    backend: string;
    settings?: GeneralSettings;
};

const emptyVersion = '* * *';

const initialState: AppSettingsState = {
    frontend: import.meta.env['VITE_RELEASE_VERSION'] || emptyVersion,
    backend: emptyVersion,
};

const stateKey = 'app-settings';

export const appSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBackendVersionThunk.fulfilled, (state, { payload }) => {
            state.backend = payload;
        });
        builder.addCase(getNotAuthGeneralSettingsThunk.fulfilled, (state, { payload }) => {
            state.settings = payload;
        });
    },
});

export default appSlice.reducer;
