import { type LabeledValue } from '@pages/dashboard/shared/labeled-data/labeled-data.types';
import isUndefined from 'lodash/isUndefined';

export function getHttpLink(value?: LabeledValue): string {
    if (!value) {
        return '';
    }
    const stringValue = String(value);
    if (stringValue.startsWith('https://') || stringValue.startsWith('http://')) {
        return stringValue;
    }
    return `http://${stringValue}`;
}

export function getFavicon(url: string): string | undefined {
    let favicon;
    try {
        const origin = new URL(getHttpLink(url)).origin;
        favicon = `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${origin}&size=48`;
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return favicon;
    }
}

export function objectToFormData(data: Record<string, unknown>): FormData {
    const formData = new FormData();

    const addToFormData = (key: string, value: unknown): void => {
        if (isUndefined(value)) {
            return;
        }
        if (Array.isArray(value) && value.every((item) => item instanceof File)) {
            value.forEach((item) => {
                addToFormData(key, item);
            });
        } else if (value instanceof File) {
            formData.append(key, value);
        } else if (typeof value === 'object' && value) {
            for (const subKey in value) {
                addToFormData(`${key}[${subKey}]`, (value as Record<string, unknown>)[subKey]);
            }
        } else {
            formData.append(key, String(value));
        }
    };
    for (const key in data) {
        addToFormData(key, data[key]);
    }

    return formData;
}
