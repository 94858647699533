import { clsx, type ClassValue } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';
import tailwindConfig from '../../../tailwind.config.ts';

const { fontSize = {}, spacing = {} } = tailwindConfig.theme?.extend || {};

const customTwMerge = extendTailwindMerge({
    extend: {
        theme: {
            spacing: Object.keys(spacing),
        },
        classGroups: {
            'font-size': Object.keys(fontSize).map((key) => `text-${key}`),
        },
    },
});

export function cn(...args: ClassValue[]): string {
    return customTwMerge(clsx(args));
}
