import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListBulk } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListBulk } from '@store/state.types.ts';
import { CredentialsThunks } from './credentials.thunks.ts';
import { type Credential } from './credentials.types.ts';

export type CredentialsState = StateListBulk<Credential>;

export const credentialsThunks = new CredentialsThunks();

const { stateKey, defaultThunks } = credentialsThunks;

const initialState = getInitialStateListBulk<Credential>();

export const credentialsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        clearCredentialsTabs: () => {
            return initialState;
        },
    },
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});
export const { clearCredentialsTabs } = credentialsSlice.actions;
export default credentialsSlice.reducer;
