import { ListWithBulkApiService } from '@pages/dashboard/shared/crud/list-with-bulk/list-with-bulk-api.service';
import { type CreateHoliday, type Holiday, type HolidaysFilterQuery, type UpdateHoliday } from './holidays.types';

export class HolidaysService extends ListWithBulkApiService<
    Holiday,
    HolidaysFilterQuery,
    CreateHoliday,
    UpdateHoliday
> {
    public constructor() {
        super('holidays');
    }
}

const holidaysService = new HolidaysService();
export default holidaysService;
