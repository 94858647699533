import { clearCurrentState, clearUpcoming } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { type CurrentUpcoming } from '../../../shared/absence-policies/absence-policies.types';
import { SettingsVacationPolicyThunks } from './vacation-policy.thunk';
import { type VacationPolicy } from './vacation-policy.types';

// TODO(xakeppok): do we need Settings prefix?
export type SettingsVacationPolicyState = StateSingle<CurrentUpcoming<VacationPolicy>>;

const initialState: SettingsVacationPolicyState = getInitialStateSingle();

export const settingsVacationPolicyThunks = new SettingsVacationPolicyThunks();

const { stateKey, defaultPlaningThunks } = settingsVacationPolicyThunks;

export const vacationPolicySettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultPlaningThunks, [clearCurrentState], (builder) => {
        // TODO(xakeppok): move to crud reducers?
        builder.addCase(clearUpcoming, (state) => {
            if (state.current) {
                state.current.upcoming = null;
            }
        });
    }),
});
export default vacationPolicySettingsSlice.reducer;
