import { createSlice } from '@reduxjs/toolkit';
import { getCalendarDaysThunk } from './calendar.thunk.ts';
import { type CalendarDay } from './calendar.types.ts';
import { setStateValue } from '@shared/store/store.utils.ts';

export type CalendarState = {
    days: CalendarDay[][];
    pending: boolean;
};

const initialState: CalendarState = {
    days: [],
    pending: false,
};

// TODO(xakeppok): add entity type when start with logs, etc
const stateKey = 'calendar';

export const calendarSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCalendarDaysThunk.pending, setStateValue('pending', true))
            .addCase(getCalendarDaysThunk.fulfilled, (state, { payload }) => {
                state.days = payload;
                state.pending = false;
            })
            .addCase(getCalendarDaysThunk.rejected, setStateValue('pending', false));
    },
});
export default calendarSlice.reducer;
