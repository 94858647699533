import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import * as alertService from './alerts.service.ts';
import { type Alerts } from './alerts.types.ts';

export const alertsGetType = 'alerts/get';

export const getAlertsThunk = createAsyncThunkWrapper<Alerts, void>({
    actionType: alertsGetType,
    serviceFunction: () => alertService.getAlerts(),
});
