import { ListAndCurrentApiService } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-api.service.ts';
import { type Client, type ClientList, type ClientsQuery, type CreateClient } from './clients.types.ts';

export class ClientsService extends ListAndCurrentApiService<Client, ClientList, ClientsQuery, CreateClient> {
    public constructor() {
        super('clients');
    }
}
const clientsService = new ClientsService();
export default clientsService;
