import { SingleThunks } from '@pages/dashboard/shared/crud/single/single-thunks.ts';
import { type Create } from '@shared/create/create.types.ts';
import settingsGeneralService, { type SettingsGeneralService } from './general-settings.service.ts';
import type { GeneralSettings, UpdateGeneralSettings } from './general-settings.types.ts';

export class SettingsGeneralThunks extends SingleThunks<
    SettingsGeneralService,
    GeneralSettings,
    Create<GeneralSettings>,
    UpdateGeneralSettings
> {
    public constructor() {
        super(settingsGeneralService, 'settings.general');
    }
}
