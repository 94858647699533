import { type Create } from '@shared/create/create.types.ts';
import { type DatabaseBackup } from './database-backup.types.ts';
import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service.ts';

export class SettingsDatabaseBackupService extends SingleApiService<DatabaseBackup, Create<DatabaseBackup>> {
    public constructor() {
        super('database-backup');
    }
}
const settingsDatabaseBackupService = new SettingsDatabaseBackupService();
export default settingsDatabaseBackupService;
