import httpClient from '@shared/http/http.service.ts';
import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types.ts';
import { type Activity, type ActivityQuery, type ActivityLogCharts } from './activity-log.types.ts';

const PREFIX = 'activity-log';

export const getActivityLog = (params?: ActivityQuery): Promise<Pagination<Activity>> => {
    abortPreviousRequest();
    return httpClient.get(PREFIX, { params, signal: controller.signal });
};

export const getActivityLogCharts = (params?: ActivityQuery): Promise<ActivityLogCharts> => {
    return httpClient.get(`${PREFIX}/analytic-charts`, { params });
};

let controller = new AbortController();
// TODO(xakeppok): check why do we need to do this abort
const abortPreviousRequest = (): void => {
    controller.abort();
    controller = new AbortController();
};
