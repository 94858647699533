import { EntityType } from './entity-type.enum';

export type EntityTypeDetails = {
    entityType: EntityType;
    nameKey: string;
};

export const relationEntityTypeDetailsMapping: Record<string, EntityTypeDetails> = {
    position: { entityType: EntityType.POSITIONS, nameKey: 'name' },
    client: { entityType: EntityType.CLIENTS, nameKey: 'name' },
    employee: { entityType: EntityType.EMPLOYEES, nameKey: 'fullName' },
    inventoryRecord: { entityType: EntityType.INVENTORY, nameKey: 'model' },
    document: { entityType: EntityType.DOCUMENTS, nameKey: 'fileName' },
    project: { entityType: EntityType.PROJECTS, nameKey: 'name' },
};

export const getRelationByEntityType = (entityType?: EntityType): string | undefined => {
    const [relation] =
        Object.entries(relationEntityTypeDetailsMapping).find(
            ([, { entityType: et }]: [string, EntityTypeDetails]) => et === entityType,
        ) || [];
    return relation;
};

type EntityTypeDetailsInAttachment = {
    entityTypeDetails: EntityTypeDetails;
    relation: string;
};

export const getParentEntityTypeDetailsInAttachment = (
    item: Record<string, any>,
): EntityTypeDetailsInAttachment | undefined => {
    const relation = Object.keys(relationEntityTypeDetailsMapping).find((value: string) => {
        return value in item && item![value];
    });
    return relation && relationEntityTypeDetailsMapping[relation]
        ? { entityTypeDetails: relationEntityTypeDetailsMapping[relation]!, relation }
        : undefined;
};
