import { type Create } from '@shared/create/create.types.ts';
import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service.ts';
import type { GeneralSettings, UpdateGeneralSettings } from './general-settings.types';
import httpClient from '@shared/http/http.service';
import { objectToFormData } from '@shared/http/http.utils';
import { serializeDatesInObject } from '@shared/serializer/date-body.serializer';

export class SettingsGeneralService extends SingleApiService<
    GeneralSettings,
    Create<GeneralSettings>,
    UpdateGeneralSettings
> {
    public constructor() {
        super('general-settings');
    }

    public override create(): Promise<void> {
        throw Error('Not needed');
    }

    public override update(data: UpdateGeneralSettings): Promise<void> {
        return httpClient.patch(
            `${this.prefix}`,
            objectToFormData(serializeDatesInObject(data) as UpdateGeneralSettings),
        );
    }
}
const settingsGeneralService = new SettingsGeneralService();
export default settingsGeneralService;
