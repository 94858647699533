import { useEffect } from 'react';
import { io } from 'socket.io-client';

export type SocketHandlers = Record<string, (...args: unknown[]) => void>;

let ignored: string[] = [];

const BASE_SOCKET_URI = import.meta.env.PROD ? '' : `localhost:${import.meta.env['VITE_API_PORT']}`;

export const useSocket = (namespace: string, handlers: SocketHandlers): void => {
    useEffect(() => {
        const socket = io(`${BASE_SOCKET_URI}/${namespace}`, {
            path: (import.meta.env.PROD ? '/api' : '') + '/socket.io',
            withCredentials: true,
            transports: ['websocket'],
        });

        if (ignored.includes(namespace)) {
            return;
        }

        socket.connect();

        socket.on('connect', () => {
            ignored.push(namespace);
        });

        Object.keys(handlers).forEach((event) => {
            const handler = handlers[event];
            if (handler) {
                socket.on(event, handler);
            }
        });

        return (): void => {
            if (socket.connected) {
                socket.disconnect();
                ignored = ignored.filter((name) => name !== namespace);
            }
        };
    }, []);
};
