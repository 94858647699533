import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListBulk } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListBulk } from '@store/state.types.ts';
import { LinksThunks } from './links.thunks.ts';
import { type Link } from './links.types.ts';

export type LinksState = StateListBulk<Link>;

export const linksThunks = new LinksThunks();

const { stateKey, defaultThunks } = linksThunks;

const initialState = getInitialStateListBulk<Link>();

export const linksSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        clearLinkTabs: () => {
            return initialState;
        },
    },
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});
export const { clearLinkTabs } = linksSlice.actions;
export default linksSlice.reducer;
