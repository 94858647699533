import { PlainingThunks } from '@pages/dashboard/shared/crud/planing/planing-thunks';
import { type Create } from '@shared/create/create.types';
import vacationPolicyService, { type SettingsVacationPolicyService } from './vacation-policy.service';
import { type VacationPolicy } from './vacation-policy.types';

export class SettingsVacationPolicyThunks extends PlainingThunks<
    SettingsVacationPolicyService,
    VacationPolicy,
    Create<VacationPolicy>
> {
    public constructor() {
        super(vacationPolicyService, 'settings.vacationPolicy');
    }
}
