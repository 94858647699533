import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service.ts';
import { type Create } from '@shared/create/create.types.ts';
import { type WorkingWeek } from './working-week.types.ts';

export class SettingsWorkingWeekService extends SingleApiService<WorkingWeek, Create<WorkingWeek>> {
    public constructor() {
        super('working-week');
    }
}
const settingsWorkingWeekService = new SettingsWorkingWeekService();
export default settingsWorkingWeekService;
