import { type PaginationMeta } from './pagination.types.ts';

export const paginationMetaInitial: PaginationMeta = {
    page: 1,
    pageCount: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    count: 1,
    startOf: 1,
    endOf: 1,
};
