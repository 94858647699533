import { PlainingThunks } from '@pages/dashboard/shared/crud/planing/planing-thunks';
import { type Create } from '@shared/create/create.types.ts';
import settingsWorkingWeekService, { type SettingsWorkingWeekService } from './working-week.service';
import { type WorkingWeek } from './working-week.types';

export class SettingsWorkingWeekThunks extends PlainingThunks<
    SettingsWorkingWeekService,
    WorkingWeek,
    Create<WorkingWeek>
> {
    public constructor() {
        super(settingsWorkingWeekService, 'settings.workingWeek');
    }
}
