import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import * as generalService from './app-settings.service';
import type { GeneralSettings } from '@pages/dashboard/pages/company/settings/pages/general/store/general-settings.types';

export const getBackendVersionThunk = createAsyncThunkWrapper<string, void>({
    actionType: 'app-settings/get-backend-version',
    serviceFunction: async () => {
        return generalService.getBackendVersion();
    },
});

export const getNotAuthGeneralSettingsThunk = createAsyncThunkWrapper<GeneralSettings, void>({
    actionType: 'app-settings/general',
    serviceFunction: async () => {
        return generalService.getNotAuthGeneralSettings();
    },
});
