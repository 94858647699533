import { createSlice } from '@reduxjs/toolkit';
import { type BaseState, type StateListBulk } from '@store/state.types.ts';
import { createAsyncReducer, getInitialStateListBulk } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { clearCurrentState } from '@dashboard-shared/crud/clear.actions.ts';
import { type Document } from './documents.types.ts';
import { DocumentsThunks } from './documents.thunk.ts';

type IdField = string | number;
export type DocumentsState = StateListBulk<Document> & {
    bulkPending: IdField[];
    fulfilledItems: Record<string, number>;
    rejectedItems: IdField[];
} & BaseState;

export const documentsThunks = new DocumentsThunks();

const {
    stateKey,
    defaultThunks: [, ...defaultThunksWithoutBulk],
    createDocumentThunk,
} = documentsThunks;

const initialState: DocumentsState = {
    ...getInitialStateListBulk<Document>(),
    bulkPending: [],
    fulfilledItems: {},
    rejectedItems: [],
};

const documentsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        clearFulfilled(state) {
            state.fulfilledItems = {};
        },
        clearDocumentsState() {
            return initialState;
        },
    },
    extraReducers: createAsyncReducer(defaultThunksWithoutBulk, [clearCurrentState], (builder) =>
        builder
            .addCase(createDocumentThunk.pending, (state, { meta: { arg } }) => {
                state.rejectedItems = state.rejectedItems.filter((pendingId) => pendingId !== arg.tempId);
                state.bulkPending.push(arg.tempId);
            })
            .addCase(createDocumentThunk.fulfilled, (state, { payload, meta: { arg } }) => {
                state.fulfilledItems[arg.tempId] = payload.id;
                state.bulkPending = state.bulkPending.filter((pendingId) => pendingId !== arg.tempId);
            })
            .addCase(createDocumentThunk.rejected, (state, { meta: { arg } }) => {
                state.rejectedItems.push(arg.tempId);
                state.bulkPending = state.bulkPending.filter((pendingId) => pendingId !== arg.tempId);
            }),
    ),
});

export const { clearDocumentsState, clearFulfilled } = documentsSlice.actions;

export default documentsSlice.reducer;
