import { Language } from '@libraries/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enProjects from '@customers/projects/i18n/en.json';
import enProjectTab from '@customers/projects/project-item/project-tab/i18n/en.json';
import enProjectForm from '@customers/projects/project-item/project-form/i18n/en.json';
import enFinishReopenProjectButtons from '@customers/projects/common/finish-reopen/i18n/en.json';
import enProjectsFilters from '@customers/projects/projects-filters/i18n/en.json';
import ukProjects from '@customers/projects/i18n/uk.json';
import ukProjectTab from '@customers/projects/project-item/project-tab/i18n/uk.json';
import ukProjectForm from '@customers/projects/project-item/project-form/i18n/uk.json';
import ukFinishReopenProjectButtons from '@customers/projects/common/finish-reopen/i18n/uk.json';
import ukProjectsFilters from '@customers/projects/projects-filters/i18n/uk.json';
import ruProjects from '@customers/projects/i18n/ru.json';
import ruProjectTab from '@customers/projects/project-item/project-tab/i18n/ru.json';
import ruProjectForm from '@customers/projects/project-item/project-form/i18n/ru.json';
import ruFinishReopenProjectButtons from '@customers/projects/common/finish-reopen/i18n/ru.json';
import ruProjectsFilters from '@customers/projects/projects-filters/i18n/ru.json';
import enClients from '@customers/clients/i18n/en.json';
import enClientsFilters from '@customers/clients/clients-filters/i18n/en.json';
import enClientsAlert from '@customers/clients/clients-alerts/i18n/en.json';
import ukClients from '@customers/clients/i18n/uk.json';
import ukClientsFilters from '@customers/clients/clients-filters/i18n/uk.json';
import ukClientsAlert from '@customers/clients/clients-alerts/i18n/uk.json';
import ruClients from '@customers/clients/i18n/ru.json';
import ruClientsFilters from '@customers/clients/clients-filters/i18n/ru.json';
import ruClientsAlert from '@customers/clients/clients-alerts/i18n/ru.json';
import enClientForm from '@customers/clients/client-item/client-form/i18n/en.json';
import enClientTab from '@customers/clients/client-item/client-tab/i18n/en.json';
import ukClientForm from '@customers/clients/client-item/client-form/i18n/uk.json';
import ukClientTab from '@customers/clients/client-item/client-tab/i18n/uk.json';
import ruClientForm from '@customers/clients/client-item/client-form/i18n/ru.json';
import ruClientTab from '@customers/clients/client-item/client-tab/i18n/ru.json';

export const customersI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.PROJECTS]: enProjects,
        [TranslationsNameSpaces.PROJECT_TAB]: enProjectTab,
        [TranslationsNameSpaces.PROJECT_FORM]: enProjectForm,
        [TranslationsNameSpaces.FINISH_REOPEN_PROJECT_BUTTONS]: enFinishReopenProjectButtons,
        [TranslationsNameSpaces.PROJECTS_FILTERS]: enProjectsFilters,
        [TranslationsNameSpaces.CLIENTS]: enClients,
        [TranslationsNameSpaces.CLIENTS_FILTERS]: enClientsFilters,
        [TranslationsNameSpaces.CLIENTS_ALERT]: enClientsAlert,
        [TranslationsNameSpaces.CLIENT_FORM]: enClientForm,
        [TranslationsNameSpaces.CLIENT_TAB]: enClientTab,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.PROJECTS]: ukProjects,
        [TranslationsNameSpaces.PROJECT_TAB]: ukProjectTab,
        [TranslationsNameSpaces.PROJECT_FORM]: ukProjectForm,
        [TranslationsNameSpaces.FINISH_REOPEN_PROJECT_BUTTONS]: ukFinishReopenProjectButtons,
        [TranslationsNameSpaces.PROJECTS_FILTERS]: ukProjectsFilters,
        [TranslationsNameSpaces.CLIENTS]: ukClients,
        [TranslationsNameSpaces.CLIENTS_FILTERS]: ukClientsFilters,
        [TranslationsNameSpaces.CLIENTS_ALERT]: ukClientsAlert,
        [TranslationsNameSpaces.CLIENT_FORM]: ukClientForm,
        [TranslationsNameSpaces.CLIENT_TAB]: ukClientTab,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.PROJECTS]: ruProjects,
        [TranslationsNameSpaces.PROJECT_TAB]: ruProjectTab,
        [TranslationsNameSpaces.PROJECT_FORM]: ruProjectForm,
        [TranslationsNameSpaces.FINISH_REOPEN_PROJECT_BUTTONS]: ruFinishReopenProjectButtons,
        [TranslationsNameSpaces.PROJECTS_FILTERS]: ruProjectsFilters,
        [TranslationsNameSpaces.CLIENTS]: ruClients,
        [TranslationsNameSpaces.CLIENTS_FILTERS]: ruClientsFilters,
        [TranslationsNameSpaces.CLIENTS_ALERT]: ruClientsAlert,
        [TranslationsNameSpaces.CLIENT_FORM]: ruClientForm,
        [TranslationsNameSpaces.CLIENT_TAB]: ruClientTab,
    },
};
