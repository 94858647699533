import { clearCurrentAction, clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListAndCurrent } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListAndCurrent } from '@store/state.types.ts';
import { PositionsThunks } from './positions.thunk';
import { type PositionDetail, type PositionInfo, type PositionInsights, type PositionList } from './positions.types.ts';
import { setStateValue } from '@shared/store/store.utils.ts';

export type PositionsState = StateListAndCurrent<PositionList, PositionDetail, PositionInsights> & {
    info?: PositionInfo | null;
    setDefaultPending: boolean;
    getInfoPending: boolean;
};

export const positionsThunks = new PositionsThunks();
const { getPositionInfoThunk, setDefaultPositionThunk, stateKey, defaultThunks } = positionsThunks;

const initialState: PositionsState = {
    ...getInitialStateListAndCurrent<PositionList, PositionDetail>(),
    info: null,
    setDefaultPending: false,
    getInfoPending: false,
};

export const positionsSlice = createSlice({
    name: stateKey,
    initialState: initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentAction, clearCurrentState], (builder) =>
        builder
            .addCase(getPositionInfoThunk.fulfilled, (state, { payload }) => {
                state.info = payload;
                state.getInfoPending = false;
            })
            .addCase(setDefaultPositionThunk.fulfilled, (state) => {
                if (state.current) {
                    state.current.isDefault = true;
                }
                state.setDefaultPending = false;
            })
            .addCase(setDefaultPositionThunk.pending, setStateValue('setDefaultPending', true))
            .addCase(setDefaultPositionThunk.rejected, setStateValue('setDefaultPending', false))
            .addCase(getPositionInfoThunk.pending, setStateValue('getInfoPending', true))
            .addCase(getPositionInfoThunk.rejected, setStateValue('getInfoPending', false)),
    ),
});

export default positionsSlice.reducer;
