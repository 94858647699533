import { SingleThunks } from '@pages/dashboard/shared/crud/single/single-thunks.ts';
import { type Create } from '@shared/create/create.types.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { showToast } from '@shared/toast/store/toast.thunk.ts';
import settingsMailBoxService, { type SettingsMailBoxService } from './mail-box.service.ts';
import { type MailBox } from './mail-box.types.ts';

export class SettingsMailBoxThunks extends SingleThunks<SettingsMailBoxService, MailBox, Create<MailBox>> {
    public constructor() {
        super(settingsMailBoxService, 'settings.mailBox');

        this.sendTestThunk = createAsyncThunkWrapper<void, string>({
            actionType: this.settingsMailBoxSendTest,
            serviceFunction: async (email, { dispatch }) => {
                await settingsMailBoxService.sendTestEmail(email);
                dispatch(showToast({ type: 'success', message: 'SETTINGS_MAIL_BOX.TEST_MESSAGE_SENT' }));
            },
        });
    }

    public sendTestThunk;
    public settingsMailBoxSendTest = `${this.stateKey}/send-test`;
}
