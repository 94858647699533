import { checkTokenThunk } from '@auth/store/auth.thunk.ts';
import { ACTION_TYPE_PREFIX } from '@pages/dashboard/shared/crud/crud.utils.ts';
import { ListAndCurrentThunks } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-thunks.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import employeesService, { type EmployeesService } from './employees.service.ts';
import {
    type CreateEmployee,
    type Employee,
    type EmployeeInsights,
    type EmployeeList,
    type EmployeesFilterQuery,
} from './employees.types.ts';
export class EmployeesThunks extends ListAndCurrentThunks<
    EmployeesService,
    Employee,
    EmployeeList,
    EmployeesFilterQuery,
    CreateEmployee,
    EmployeeInsights
> {
    public constructor() {
        super(employeesService, 'employees', (employee) => employee.fullName);
    }

    public changePhotoThunk = createAsyncThunkWrapper<void, File>({
        actionType: `${this.stateKey}/current/${ACTION_TYPE_PREFIX}/change-photo`,
        serviceFunction: async (data, { dispatch }, state) => {
            const fn = this.service.changePhoto.bind(this.service);
            await fn(data);
            const slug = state.auth.employee?.slug;

            if (slug) {
                dispatch(this.getOneThunk(slug));
                dispatch(checkTokenThunk());
            }
        },
    });
}
