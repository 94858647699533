import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types';
import httpClient from '@shared/http/http.service';
import omitBy from 'lodash/omitBy';

export abstract class ListAndCurrentApiService<
    T extends { slug: string },
    R,
    Query,
    Create,
    Insights = any,
    Update = Partial<Create>,
> {
    public constructor(public readonly prefix: string) {}

    public create(data: Create): Promise<T> {
        return httpClient.post(`${this.prefix}`, data);
    }

    public getOne(slug: string): Promise<T> {
        return httpClient.get(`${this.prefix}/${slug}`);
    }

    public delete(id: number): Promise<T> {
        return httpClient.delete(`${this.prefix}/${id}`);
    }

    public recover(id: number): Promise<T> {
        return httpClient.post(`${this.prefix}/recover/${id}`);
    }

    public archive(id: number): Promise<T> {
        return httpClient.post(`${this.prefix}/archive/${id}`);
    }

    public getAll(query: Query): Promise<Pagination<R>> {
        return httpClient.get(this.prefix, {
            params: query,
        });
    }

    public getInsights = (query: Query): Promise<Insights> => {
        const params = omitBy(query || {}, (key: string) => ['page', 'take'].includes(key));
        return httpClient.get(`${this.prefix}/insights`, { params });
    };

    public update(id: number, data: Update): Promise<T> {
        return httpClient.patch(`${this.prefix}/${id}`, data);
    }
}
