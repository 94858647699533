import { ACTION_TYPE_PREFIX } from '@pages/dashboard/shared/crud/crud.utils.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { showToast } from '@shared/toast/store/toast.thunk.ts';
import { AttachmentListWithBulkThunks } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-thunks.ts';
import documentsService, { type DocumentsService } from './documents.service.ts';
import { type CreateDocument, type Document, type DocumentsQuery, type UpdateDocument } from './documents.types.ts';

export class DocumentsThunks extends AttachmentListWithBulkThunks<
    DocumentsService,
    Document,
    DocumentsQuery,
    CreateDocument,
    UpdateDocument
> {
    public createDocumentThunk;
    public constructor() {
        super(documentsService, 'documents');
        this.createDocumentThunk = createAsyncThunkWrapper<Document, CreateDocument>({
            actionType: `${this.stateKey}/${ACTION_TYPE_PREFIX}/create-one`,
            serviceFunction: async (data, { dispatch }) => {
                const document = await documentsService.uploadDocument(data);
                dispatch(showToast({ type: 'success', message: 'DOCUMENTS.CREATED' }));
                return document;
            },
        });
    }
}
