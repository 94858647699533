import * as activitiesService from '@activity-log/store/activity-log.service.ts';
import { type Activity, type ActivityQuery } from '@activity-log/store/activity-log.types.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { rejectRecentActivities } from '@dashboard-shared/recent-activities/store/recent-activities.slice.ts';

export const recentActivitiesGetType = 'activities/recent/get';

export const getRecentActivitiesThunk = createAsyncThunkWrapper<Activity[], ActivityQuery>({
    actionType: recentActivitiesGetType,
    serviceFunction: async (query) => {
        const { items } = await activitiesService.getActivityLog({ ...query, take: 5 });
        return items;
    },
    errorCallback: (error, dispatch) => {
        if (error) {
            //TODO(xakeppok) check how to make without additional action
            dispatch(rejectRecentActivities());
        }
    },
});
