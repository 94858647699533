import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types';
import { type BulkCreate } from '@pages/dashboard/shared/entity-tabs/shared/bulk.types';
import httpClient from '@shared/http/http.service';

export abstract class AttachmentListWithBulkApiService<R, Query, Create, Update = Partial<Create>> {
    public constructor(public readonly prefix: string) {}

    public delete(id: number): Promise<void> {
        return httpClient.delete(`${this.prefix}/${id}`);
    }

    public getAll(query: Query): Promise<Pagination<R>> {
        return httpClient.get(this.prefix, {
            params: query,
        });
    }

    public update(id: number, data: Update): Promise<void> {
        return httpClient.patch(`${this.prefix}/${id}`, data);
    }

    public bulk(bulk: BulkCreate<Create>): Promise<void> {
        return httpClient.post(`${this.prefix}/bulk`, bulk);
    }
}
