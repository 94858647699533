import { type ReactElement } from 'react';

const SuccessSvg = (): ReactElement => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#22AD5C" />
            <g clipPath="url(#clip0_3131_43967)">
                <path
                    d="M18 9.50626C13.3031 9.50626 9.50626 13.3031 9.50626 18C9.50626 22.6969 13.3031 26.5219 18 26.5219C22.6969 26.5219 26.5219 22.6969 26.5219 18C26.5219 13.3031 22.6969 9.50626 18 9.50626ZM18 25.2563C14.0063 25.2563 10.7719 21.9938 10.7719 18C10.7719 14.0063 14.0063 10.7719 18 10.7719C21.9938 10.7719 25.2563 14.0344 25.2563 18.0281C25.2563 21.9938 21.9938 25.2563 18 25.2563Z"
                    fill="white"
                />
                <path
                    d="M20.4188 15.3844L17.0719 18.6469L15.5531 17.1563C15.3 16.9031 14.9063 16.9313 14.6531 17.1563C14.4 17.4094 14.4281 17.8031 14.6531 18.0563L16.4531 19.8C16.6219 19.9688 16.8469 20.0531 17.0719 20.0531C17.2969 20.0531 17.5219 19.9688 17.6906 19.8L21.3188 16.3125C21.5719 16.0594 21.5719 15.6656 21.3188 15.4125C21.0656 15.1594 20.6719 15.1594 20.4188 15.3844Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_3131_43967">
                    <rect width="18" height="18" fill="white" transform="translate(9 9)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SuccessSvg;
