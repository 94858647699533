import { ListAndCurrentThunks } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-thunks.ts';
import clientsService, { type ClientsService } from './clients.service';
import { type Client, type ClientList, type ClientsQuery, type CreateClient } from './clients.types.ts';

export class ClientsThunks extends ListAndCurrentThunks<
    ClientsService,
    Client,
    ClientList,
    ClientsQuery,
    CreateClient
> {
    public constructor() {
        super(clientsService, 'clients', (client) => client.name);
    }
}
