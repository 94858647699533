import { AttachmentListWithBulkApiService } from '../../crud/attachment-list-with-bulk/attachment-list-with-bulk-api.service';
import { type CreateCredential, type Credential, type CredentialsQuery } from './credentials.types';

export class CredentialsService extends AttachmentListWithBulkApiService<
    Credential,
    CredentialsQuery,
    CreateCredential
> {
    public constructor() {
        super('credentials');
    }
}

const credentialsService = new CredentialsService();
export default credentialsService;
