import { createSlice } from '@reduxjs/toolkit';
import { type InitialBaseState } from '@store/state.types.ts';
import { getAlertsThunk } from './alerts.thunk';
import { type Alerts } from './alerts.types.ts';

export type AlertsState = InitialBaseState & {
    common?: Alerts | null;
};

const initialState: AlertsState = {
    isInitialized: false,
    pending: false,
};

const stateKey = 'alerts';

export const alertsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAlertsThunk.pending, (state) => {
                state.pending = true;
            })
            .addCase(getAlertsThunk.fulfilled, (state, { payload }) => {
                state.common = payload;
                state.isInitialized = true;
                state.pending = false;
            })
            .addCase(getAlertsThunk.rejected, (state) => {
                state.common = null;
                state.pending = false;
            });
    },
});

export default alertsSlice.reducer;
