import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { clearCurrentState, clearUpcoming } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { SettingsWorkingWeekThunks } from './working-week.thunk';
import { type WorkingWeek } from './working-week.types';
import { type CurrentUpcoming } from '../../../shared/absence-policies/absence-policies.types';

export type SettingsWorkingWeekState = StateSingle<CurrentUpcoming<WorkingWeek>>;

export const settingsWorkingWeekThunks = new SettingsWorkingWeekThunks();

const { stateKey, defaultPlaningThunks } = settingsWorkingWeekThunks;

const initialState: SettingsWorkingWeekState = getInitialStateSingle();

export const workingWeekSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultPlaningThunks, [clearCurrentState], (builder) => {
        builder.addCase(clearUpcoming, (state) => {
            if (state.current) {
                state.current.upcoming = null;
            }
        });
    }),
});

export default workingWeekSettingsSlice.reducer;
