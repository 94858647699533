import { type Create } from '@shared/create/create.types.ts';
import httpClient from '@shared/http/http.service.ts';
import {
    type Position,
    type PositionDetail,
    type PositionInfo,
    type PositionInsights,
    type PositionList,
    type PositionsQuery,
} from './positions.types.ts';
import { ListAndCurrentApiService } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-api.service.ts';

export class PositionsService extends ListAndCurrentApiService<
    PositionDetail,
    PositionList,
    PositionsQuery,
    Create<Position>,
    PositionInsights
> {
    public constructor() {
        super('positions');
    }

    public getPositionInfo(slug: string): Promise<PositionInfo> {
        return httpClient.get(`${this.prefix}/${slug}/info`);
    }

    public setDefaultPosition(slug: string): Promise<void> {
        return httpClient.patch(`${this.prefix}/${slug}/default`);
    }
}
const positionsService = new PositionsService();
export default positionsService;
