export enum ActivityType {
    UNKNOWN = 'unknown',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export enum CustomActivityType {
    ARCHIVE = 'archive',
    RECOVER = 'recover',
}
