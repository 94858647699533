import {
    type CreateInventoryRecord,
    type InventoryFilterQuery,
    type InventoryList,
    type InventoryRecord,
    type UpdateInventoryRecord,
} from '@inventory/store/inventory.types.ts';
import { ListAndCurrentApiService } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-api.service';
import httpClient from '@shared/http/http.service';
import { objectToFormData } from '@shared/http/http.utils';
import { serializeDatesInObject } from '@shared/serializer/date-body.serializer';

export class InventoryService extends ListAndCurrentApiService<
    InventoryRecord,
    InventoryList,
    InventoryFilterQuery,
    CreateInventoryRecord
> {
    public constructor() {
        super('inventory');
    }

    public override update(id: number, data: Partial<CreateInventoryRecord>): Promise<InventoryRecord> {
        return httpClient.patch(
            `${this.prefix}/${id}`,
            objectToFormData(serializeDatesInObject(data) as UpdateInventoryRecord),
        );
    }

    public override create(data: CreateInventoryRecord): Promise<InventoryRecord> {
        return httpClient.post(this.prefix, objectToFormData(serializeDatesInObject(data) as CreateInventoryRecord));
    }
}

const inventoryService = new InventoryService();
export default inventoryService;
