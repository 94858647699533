import activitiesReducer, { type ActivityState } from '@activity-log/store/activity-log.slice';
import authReducer, { type AuthState } from '@auth/store/auth.slice.ts';
import { logoutThunk } from '@auth/store/auth.thunk.ts';
import backupsReducer, { type BackupsState } from '@backups/store/backups.slice';
import calendarReducer, { type CalendarState } from '@calendar/store/calendar.slice';
import recentActivityLogReducer, {
    type RecentActivityState,
} from '@dashboard-shared/recent-activities/store/recent-activities.slice.ts';
import clientsReducer, { type ClientsState } from '@pages/dashboard/pages/customers/clients/store/clients.slice';
import projectsReducer, { type ProjectsState } from '@pages/dashboard/pages/customers/projects/store/projects.slice';
import credentialsReducer, { type CredentialsState } from '@pages/dashboard/shared/credentials/store/credentials.slice';
import documentsReducer, { type DocumentsState } from '@pages/dashboard/shared/documents/store/documents.slice';
import entityTabCountsReducer, {
    type EntityTabsState,
} from '@pages/dashboard/shared/entity-tabs/store/entity-tabs.slice';
import linksReducer, { type LinksState } from '@pages/dashboard/shared/links/store/links.slice';
import lookupsReducer, { type LookupsState } from '@pages/dashboard/shared/lookups/lookups.slice';
import positionsReducer, { type PositionsState } from '@positions/store/positions.slice';
import structureReducer, { type StructureState } from '@structure/store/structure.slice';
import { type ThunkAction, type UnknownAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import inventoryReducer, { type InventoryState } from '@inventory/store/inventory.slice.ts';
import employeesReducer, { type EmployeesState } from '@employees/store/employees.slice.ts';
import settingsDatabaseBackupReducer, {
    type SettingsDatabaseBackupState,
} from '@settings-database-backup/store/database-backup.slice';
import holidaysScheduleReducer, {
    type SettingsHolidaysScheduleState,
} from '@settings-holidays/holidays-schedule/store/holidays-schedule.slice';
import holidaysReducer, { type HolidaysState } from '@settings-holidays/holidays/store/holidays.slice';
import settingsMailBoxReducer, { type SettingsMailState } from '@settings/pages/mail-box/store/mail-box.slice';
import settingsSicknessPolicyReducer, {
    type SettingsSicknessPolicyState,
} from '@settings-sickness-policy/store/sickness-policy.slice';
import settingsStorageReducer, { type SettingsStorageState } from '@settings-storage/store/storage.slice';
import settingsVacationPolicyReducer, {
    type SettingsVacationPolicyState,
} from '@settings-vacation-policy/store/vacation-policy.slice';
import workingWeekReducer, { type SettingsWorkingWeekState } from '@settings-working-week/store/working-week.slice';
import alertsReducer, { type AlertsState } from '@shared/alert/store/alerts.slice';
import toastReducer from '@shared/toast/store/toast.slice.ts';
import { type Toast } from '@shared/toast/store/toast.thunk.ts';
import appStateReducer, { type AppSettingsState } from '../app-initializer/store/app-settings.slice';
import generalSettingsReducer, {
    type SettingsGeneralState,
} from '@pages/dashboard/pages/company/settings/pages/general/store/general-settings.slice';

export type SettingsState = {
    general: SettingsGeneralState;
    mailBox: SettingsMailState;
    databaseBackup: SettingsDatabaseBackupState;
    holidaysSchedule: SettingsHolidaysScheduleState;
    workingWeek: SettingsWorkingWeekState;
    storage: SettingsStorageState;
    vacationPolicy: SettingsVacationPolicyState;
    sicknessPolicy: SettingsSicknessPolicyState;
};

export type AppRecentState = {
    activities: RecentActivityState;
};

export type AppState = {
    lookups: LookupsState;
    appSettings: AppSettingsState;
    auth: AuthState;
    toasts: Toast[];
    activities: ActivityState;
    positions: PositionsState;
    backups: BackupsState;
    employees: EmployeesState;
    alerts: AlertsState;
    settings: SettingsState;
    documents: DocumentsState;
    inventory: InventoryState;
    links: LinksState;
    credentials: CredentialsState;
    recent: AppRecentState;
    entityTabsCounts: EntityTabsState;
    holidays: HolidaysState;
    calendar: CalendarState;
    clients: ClientsState;
    projects: ProjectsState;
    structure: StructureState;
};

const appReducer = (state: AppState | undefined, action: UnknownAction): AppState => {
    let newState = state;
    if (state && action.type === logoutThunk.fulfilled.type) {
        newState = {
            appSettings: state.appSettings,
        } as typeof state;
    }
    return combineReducers({
        lookups: lookupsReducer,
        appSettings: appStateReducer,
        auth: authReducer,
        toasts: toastReducer,
        activities: activitiesReducer,
        positions: positionsReducer,
        backups: backupsReducer,
        employees: employeesReducer,
        alerts: alertsReducer,
        settings: combineReducers({
            general: generalSettingsReducer,
            mailBox: settingsMailBoxReducer,
            databaseBackup: settingsDatabaseBackupReducer,
            holidaysSchedule: holidaysScheduleReducer,
            workingWeek: workingWeekReducer,
            storage: settingsStorageReducer,
            vacationPolicy: settingsVacationPolicyReducer,
            sicknessPolicy: settingsSicknessPolicyReducer,
        }),
        documents: documentsReducer,
        links: linksReducer,
        credentials: credentialsReducer,
        inventory: inventoryReducer,
        recent: combineReducers({
            activities: recentActivityLogReducer,
        }),
        entityTabsCounts: entityTabCountsReducer,
        holidays: holidaysReducer,
        calendar: calendarReducer,
        clients: clientsReducer,
        projects: projectsReducer,
        structure: structureReducer,
    })(newState, action);
};

export const store = configureStore({
    reducer: appReducer,
    devTools: import.meta.env.DEV,
    middleware: (getDefaultMiddleware: any) => getDefaultMiddleware(),
});

export type AppDispatch = UnknownAction | any;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>;
export default appReducer;
