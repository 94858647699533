import { createSlice } from '@reduxjs/toolkit';
import { delayRemove, type Toast, showToast } from './toast.thunk.ts';

const initialState: Toast[] = [];

export const toastsSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        removeToast(state, { payload }: { payload: string }) {
            return state.filter(({ id }: Toast) => id !== payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(showToast.fulfilled, (state, action) => {
            return [...state, action.payload.toast];
        });
        builder.addCase(delayRemove.fulfilled, (state, action) => {
            return state.filter((toast) => toast.id !== action.payload.id);
        });
    },
});

export const { removeToast } = toastsSlice.actions;
export default toastsSlice.reducer;
