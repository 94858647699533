import { type StateKey } from '@store/state.types';
import { clearUpcoming } from '../clear.actions';
import { deleteSingleThunk } from '../delete.thunks';
import { type SingleApiService } from '../single/single-api.service';
import { SingleThunks } from '../single/single-thunks';

export abstract class PlainingThunks<Service extends SingleApiService<T, Create>, T, Create> extends SingleThunks<
    Service,
    T,
    Create
> {
    public override readonly deleteThunk;

    public constructor(service: Service, stateKey: StateKey) {
        super(service, stateKey);
        this.deleteThunk = deleteSingleThunk({
            stateKey,
            successMessage: `${this.successMessagePrefix}.DELETED`,
            thunksOnSuccess: () => clearUpcoming(),
            serviceFunction: service.delete.bind(service),
        });
    }
}
