import { type InventoryRecordForm } from '@inventory/inventory-item/inventory-form/inventory-form.tsx';
import {
    type CreateInventoryRecord,
    type InventoryFilterQuery,
    type InventoryList,
    type InventoryRecord,
} from '@inventory/store/inventory.types.ts';
import { ListAndCurrentThunks } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-thunks.ts';
import { getImageFileFromUrl } from '@shared/image/image.utils.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import inventoryService, { type InventoryService } from './inventory.service';

export class InventoryThunks extends ListAndCurrentThunks<
    InventoryService,
    InventoryRecord,
    InventoryList,
    InventoryFilterQuery,
    CreateInventoryRecord
> {
    public forceCreateThunk;
    public constructor() {
        super(inventoryService, 'inventory', (inventoryRecord) => inventoryRecord.model);
        this.forceCreateThunk = createAsyncThunkWrapper<void, InventoryRecordForm>({
            actionType: `${this.stateKey}/forceCreate`,
            serviceFunction: async (payload) => {
                const imagePrs = payload.images.map(async ({ fileName }) => {
                    const url = `${this.stateKey}/images/${fileName}`;
                    return getImageFileFromUrl(url);
                });
                const images = await Promise.all(imagePrs);
                payload.newImages.push(...(images.filter(Boolean) as File[]));
                payload.images = [];
            },
        });
    }
}
