import { createSlice } from '@reduxjs/toolkit';
import { type StateListAndCurrent } from '@store/state.types.ts';
import { clearCurrentAction, clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListAndCurrent } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { ClientsThunks } from './clients.thunk';
import { type Client, type ClientList } from './clients.types.ts';

export type ClientsState = StateListAndCurrent<ClientList, Client>;

export const clientsThunks = new ClientsThunks();
const { stateKey, defaultThunks } = clientsThunks;

const initialState: ClientsState = {
    ...getInitialStateListAndCurrent<ClientList, Client>(),
};

const clientsSlice = createSlice({
    name: stateKey,
    initialState: initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentAction, clearCurrentState]),
});

export default clientsSlice.reducer;
