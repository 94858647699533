import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service';
import { type Create } from '@shared/create/create.types.ts';
import { type VacationPolicy } from './vacation-policy.types';

export class SettingsVacationPolicyService extends SingleApiService<VacationPolicy, Create<VacationPolicy>> {
    public constructor() {
        super('absence-policies/vacation');
    }
}
const vacationPolicyService = new SettingsVacationPolicyService();
export default vacationPolicyService;
