import type { GeneralSettings } from '@pages/dashboard/pages/company/settings/pages/general/store/general-settings.types';
import httpClient from '@shared/http/http.service';

export const getBackendVersion = (): Promise<string> => {
    return httpClient.get('');
};

export const getNotAuthGeneralSettings = (): Promise<GeneralSettings> => {
    return httpClient.get('general-settings/not-auth');
};
