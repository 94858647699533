import { createAsyncThunk } from '@reduxjs/toolkit';

export type ToastType = 'success' | 'error' | 'info' | 'warning';

export type Toast = {
    id: `${string}-${string}-${string}-${string}-${string}`;
    type: ToastType;
    message: string;
    props?: Record<string, string>;
    suffixName?: string;
};

export const showToast = createAsyncThunk('toast/show', async (toast: Omit<Toast, 'id'>, { dispatch }) => {
    const id = crypto.randomUUID() as Toast['id'];
    dispatch(delayRemove(id));
    return Promise.resolve({
        toast: {
            id,
            ...toast,
        },
    });
});

export const delayRemove = createAsyncThunk('toast/delayRemove', async (id: Toast['id']) => {
    return new Promise<{ id: string }>((res) => {
        setTimeout(() => {
            res({ id });
        }, 4000);
    });
});
