import { format } from 'date-fns';

type Serializable = Date | SerializableArray | SerializableObject | string | number | boolean | null | File | File[];
type SerializableArray = Serializable[];
type SerializableObject = Record<string, any>;

const isDate = (value: Serializable): value is Date => value instanceof Date;

const isArray = (value: Serializable): value is SerializableArray => Array.isArray(value);

const isObject = (value: Serializable): value is SerializableObject =>
    typeof value === 'object' && value !== null && !isDate(value) && !isArray(value);

const isFile = (value: Serializable): value is File => value instanceof File;

const isFileArray = (value: Serializable): value is File[] =>
    Array.isArray(value) && value.every((item) => item instanceof File);

export const serializeDatesInObject = (value: Serializable): Serializable => {
    if (isDate(value)) {
        return format(value, 'yyyy-MM-dd');
    } else if (isArray(value)) {
        return value.map(serializeDatesInObject);
    } else if (isObject(value)) {
        const serializedEntries = Object.entries(value).map(([key, val]) => {
            if (!isFile(val) && !isFileArray(val)) {
                return [key, serializeDatesInObject(val)];
            }
            return [key, val];
        });
        return Object.fromEntries(serializedEntries);
    }
    return value;
};
