import { createSlice } from '@reduxjs/toolkit';
import { type StateList } from '@store/state.types.ts';
import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateList } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { getActivitiesThunk, getActivityChartsThunk } from './activity-log.thunk';
import { type Activity, type ActivityLogCharts } from './activity-log.types.ts';

export type ActivityState = StateList<Activity> & {
    charts: ActivityLogCharts;
};

const initialState = {
    ...getInitialStateList<Activity>(),
    charts: {
        pending: false,
        types: {
            create: 0,
            update: 0,
            delete: 0,
        },
    },
};

export const activityLogSlice = createSlice({
    name: 'activities',
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer([getActivitiesThunk], [clearCurrentState], (builder) => {
        builder
            .addCase(getActivityChartsThunk.fulfilled, (state, { payload }) => {
                state.charts = payload;
                state.charts.pending = false;
            })
            .addCase(getActivityChartsThunk.pending, (state) => {
                state.charts.pending = true;
            })
            .addCase(getActivityChartsThunk.rejected, (state) => {
                state.charts = { ...initialState.charts };
                state.charts.pending = false;
            });
    }),
});

export default activityLogSlice.reducer;
