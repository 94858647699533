import { clearCurrentAction, clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListAndCurrent } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListAndCurrent } from '@store/state.types.ts';
import { ProjectsThunks } from './projects.thunk';
import { type Project, type ProjectList } from './projects.types';
import { setStateValue } from '@shared/store/store.utils';

export type ProjectsState = StateListAndCurrent<ProjectList, Project> & {
    changeStatusProjectPending: boolean;
};

// TODO(xakeppok): rename all thunk to thunks and files to thunks?
export const projectsThunks = new ProjectsThunks();
const { finishThunk, reopenThunk, stateKey, defaultThunks } = projectsThunks;

const initialState: ProjectsState = {
    ...getInitialStateListAndCurrent<ProjectList, Project>(),
    changeStatusProjectPending: false,
};

export const projectsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentAction, clearCurrentState], (builder) =>
        builder
            .addCase(finishThunk.pending, setStateValue('changeStatusProjectPending', true))
            .addCase(finishThunk.fulfilled, setStateValue('changeStatusProjectPending', false))
            .addCase(finishThunk.rejected, setStateValue('changeStatusProjectPending', false))
            .addCase(reopenThunk.pending, setStateValue('changeStatusProjectPending', true))
            .addCase(reopenThunk.fulfilled, setStateValue('changeStatusProjectPending', false))
            .addCase(reopenThunk.rejected, setStateValue('changeStatusProjectPending', false)),
    ),
});

export default projectsSlice.reducer;
