// TODO(lachesse): quality check crashes when import from 'lodash/isEqual'
import { ListAndCurrentThunks } from '@pages/dashboard/shared/crud/list-and-current/list-and-current-thunks.ts';
import { type Create } from '@shared/create/create.types.ts';
import { createAsyncThunkWrapper } from '@shared/store/store.utils.ts';
import { showToast } from '@shared/toast/store/toast.thunk.ts';
import positionsService, { type PositionsService } from './positions.service';
import {
    type Position,
    type PositionDetail,
    type PositionInfo,
    type PositionInsights,
    type PositionList,
    type PositionsQuery,
} from './positions.types.ts';

export class PositionsThunks extends ListAndCurrentThunks<
    PositionsService,
    PositionDetail,
    PositionList,
    PositionsQuery,
    Create<Position>,
    PositionInsights,
    Partial<PositionDetail>
> {
    public readonly getPositionInfoThunk;
    public readonly setDefaultPositionThunk;
    public readonly positionsInfoType = `${this.stateKey}/info`;
    public readonly positionsSetDefaultType = `${this.stateKey}/set-default`;

    public constructor() {
        super(positionsService, 'positions', (position) => position.name);

        this.getPositionInfoThunk = createAsyncThunkWrapper<PositionInfo, { slug: string }>({
            actionType: this.positionsInfoType,
            serviceFunction: ({ slug }) => {
                return positionsService.getPositionInfo(slug);
            },
        });

        this.setDefaultPositionThunk = createAsyncThunkWrapper<void, { slug: string }>({
            actionType: this.positionsSetDefaultType,
            serviceFunction: async ({ slug }, { dispatch }) => {
                await positionsService.setDefaultPosition(slug);
                dispatch(showToast({ type: 'success', message: 'POSITIONS.UPDATED' }));
            },
        });
    }
}
