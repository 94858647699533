import { checkTokenThunk } from '@auth/store/auth.thunk.ts';
import { Language } from '@libraries/language.enum';
import { dummyQuery, getLogoUrl } from '@pages/dashboard/shared/file/file.utils';
import { getAlertsThunk } from '@shared/alert/store/alerts.thunk';
import Loader from '@shared/loader/loader';
import { useSocket } from '@shared/socket/socket.hook';
import i18n from 'i18next';
import { type PropsWithChildren, type ReactElement, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { resources } from '../i18n';
import { type RootState } from '../store/store';
import { useAppDispatch, useAppSelector } from '../store/store.hooks';
import { getBackendVersionThunk, getNotAuthGeneralSettingsThunk } from './store/app-settings.thunk';
import { GeneralSettingsIconsPath } from '@libraries/general-settings/general-settings-icons-path.enum';

let ignore = false;

const AppInitializer = ({ children }: PropsWithChildren): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { sessionChecked, isAuthenticated } = useAppSelector((state: RootState) => state.auth);
    const language = useAppSelector((state) => state.auth.language);
    const settings = useAppSelector((state) => state.appSettings.settings);

    // TODO(xakeppok): make enum with ApiRoutes
    useSocket('general-settings', {
        refresh: () => {
            dispatch(getNotAuthGeneralSettingsThunk());
        },
    });

    useEffect(() => {
        if (!settings) {
            return;
        }

        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        const faviconLink = getLogoUrl(GeneralSettingsIconsPath.FAVICON);
        if (favicon && faviconLink) {
            favicon.href = dummyQuery(faviconLink);
        }
    }, [settings]);

    useEffect(() => {
        if (ignore) {
            return;
        }
        dispatch(getBackendVersionThunk());
        dispatch(getNotAuthGeneralSettingsThunk());
        const systemLanguage = navigator.language;
        const languages: Language[] = [Language.EN, Language.UK, Language.RU];
        const foundSystemLanguage = languages.find((language) => systemLanguage.includes(language));
        const lng =
            (language && languages.find((lang) => lang.includes(language)) ? language : null) ??
            foundSystemLanguage ??
            Language.EN;

        i18n.use(initReactI18next)
            .init({
                resources,
                lng: lng,
                fallbackLng: lng,
                interpolation: {
                    escapeValue: false,
                },
            })
            .catch();
        if (!sessionChecked) {
            dispatch(checkTokenThunk());
        }
        return (): void => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getAlertsThunk());
        }
    }, [isAuthenticated]);

    if (!sessionChecked) {
        return <Loader />;
    }

    return <>{children}</>;
};

export default AppInitializer;
